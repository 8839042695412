import React, { Component } from "react";
import {Link} from 'react-router-dom'
import { connect } from "react-redux";
import { formatNumber } from "../../config/TYPE";
import { toast } from "react-toastify";

class YourOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      provinces: ""
    };
  }

  handSubmit = () => {
    this.props.submitOrder();
  };

  render() {
    const { items} = this.props;
    let sumPrice = 0;
    let sumDiscount = 0;
    
    if (items.length > 0) {
      sumPrice = items.reduce((sum, item) => {
        return sum += item.quantity * item.price
      }, 0)

      sumDiscount = items.reduce((sum, item) => {
        if(item.discountPrice) {
          return sum += item.quantity * (item.price - item.discountPrice)
        }
        return sum
      }, 0)
    }

    return (
      <div className="col-lg-10 col-12 mb-2" style={{ margin: "auto" }}>
        <div className="your-order">
          <h3>Đơn hàng của bạn</h3>
          <div className="your-order-table table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th className="cart-product-name">Sản phẩm</th>
                  <th className="cart-product-total">Tổng tiền</th>
                </tr>
              </thead>
              <tbody>
                {items && items.length
                  ? items.map((item, index) => {
                      return (
                        <tr className="cart_item" key={index}>
                          <td className="cart-product-name">
                            <div>
                              {item.nameProduct}
                              <strong
                                className="product-quantity"
                                style={{
                                  paddingLeft: 10,
                                  color: "coral",
                                  fontStyle: "italic",
                                }}
                              >
                                x{item.quantity}
                              </strong>
                            </div>
                            {
                              item.categories &&
                              <div className="cart-categories">
                              {
                                  item.categories.map((category, index) => {
                                  if(index === item.categories.length - 1) {
                                      return <Link to={`/categories/${category.categoryId || ''}`} key={index} className="order-item-categoryName">{category.category ? category.category.nameCategory : ''}</Link>
                                  }
                                  return <Link to={`/categories/${category.categoryId || ''}`} key={index} className="order-item-categoryName">{category.category ? `${category.category.nameCategory}, ` : ''}</Link>
                                  })
                              }
                              </div>
                            }
                            {
                              item.categoryId_categories &&
                              <div className="cart-categories">
                              {
                                  item.categoryId_categories.map((category, index) => {
                                  if(index === item.categoryId_categories.length - 1) {
                                      return <Link to={`/categories/${category.id || ''}`} key={index} className="cart-item-mobile-categoryName">{ category.nameCategory || ''}</Link>
                                  }
                                  return <Link to={`/categories/${category.id || ''}`} key={index} className="cart-item-mobile-categoryName">{`${category.nameCategory}, ` || ''}</Link>
                                  })
                              }
                              </div>
                            }
                            {item.note && <div className="order-note-item"> Ghi chú: {item.note}</div>}
                          </td>
                          <td className="cart-product-total">
                            <span className="amount">
                              {formatNumber.format(item.quantity * item.price)}
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
              <tfoot>
                <tr className="cart-subtotal">
                  <th>Tổng giá</th>
                  <td>
                    <span className="amount">
                      {sumPrice ? formatNumber.format(sumPrice) : 0}
                    </span>
                  </td>
                </tr>
                <tr className="cart-subtotal">
                  <th>Khuyễn mãi</th>
                  <td>
                    <span className="amount">
                      - {sumDiscount ? formatNumber.format(sumDiscount) : 0}
                    </span>
                  </td>
                </tr>
                <tr className="order-total">
                  <th>Tổng đơn</th>
                  <td>
                    <strong>
                      <span className="amount" style={{ color: "#e23513" }}>
                        {sumPrice ? formatNumber.format(sumPrice - sumDiscount) : 0}
                      </span>
                    </strong>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div className="payment-accordion">
            <div
              onClick={this.handSubmit}
              className="order-button-payment"
            >
              <input type="submit" value="Đặt hàng" />
            </div>
            <div className="container-buyMore"><Link to="/cart" className="buyMore">Chỉnh sửa đơn hàng</Link></div>
            {/* <div className = "container-action-checkout">
              <ReCAPTCHA
                ref={e => (this.captcha = e)}
                sitekey="6Le1z5gcAAAAAOmkg87A_43dhBOuSNcg88vw5-GP"
                onChange={this.onChangeCapcha}
              />
            </div> */}
          </div>
          
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    items: state.cart,
  };
};

export default connect(mapStateToProps, null)(YourOrder);

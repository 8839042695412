import React, { Component } from 'react'
import HistoryBookingDetail from '../components/HistoryBooking/HistoryBookingDetail';
import LinkHere from '../components/LinkHere/LinkHere'

export default class HistoryBookingDetailPage extends Component {
  render() {
    const {orderId} = this.props.match.match.params;

    return (
      <div>
        <LinkHere title='Chi tiết đơn hàng'></LinkHere>
        <HistoryBookingDetail id = {orderId}/>
      </div>
    )
  }
}

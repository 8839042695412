import React, { Component } from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatNumber } from '../../../../config/TYPE';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'
import { actFetchRatingsRequest, actAddFavoriteRequest } from '../../../../redux/actions/rating';
import { actGetProductRequest, actFetchProductsOtherRequest } from '../../../../redux/actions/products';
import { actAddCartRequest } from '../../../../redux/actions/cart';
import { startLoading, doneLoading } from '../../../../utils/loading'
import '../style.css'
import {config} from 'config';
import WatermarkImage from 'assets/images/frontbgr.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

toast.configure()

class ProductByCateItem extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      quantity: 1,
      note: '',
      redirectToDetail: false
    }
  }

  upItem = (quantity) => {
    this.setState({
      quantity: quantity + 1
    })
  }

  downItem = (quantity) => {
    if (quantity <= 1) {
      return
    }
    this.setState({
      quantity: quantity - 1
    })
  }

  handleChange = event => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({
      [name]: value
    });
  }

  handleChangeNumber = async (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({
        quantity: e.target.value
      })
    }
  }

  getInfoProduct = (id) => {
    this.setState({
      quantity: 1,
      note: ''
    })
    this.props.getProductDetail(id);
  }

  getToProductDetail = async (id, cateogryId) => {
    const { offset } = this.state;
    startLoading()
    await Promise.all([this.props.getProductDetail(id), this.props.fetch_products_other(offset, cateogryId), this.props.fetch_product_ratings(id) ])
    this.setState({
      redirectToDetail: true
    })
    doneLoading();
  }

  addItemToCart = async (product) => {
    startLoading()
    await this.props.addCart(product);
    doneLoading();
  }
  
  convertPrice = (price) => {
    let replacePrice = '';
    if(String(price).endsWith("000")){
      replacePrice = String(price).slice(0, -3).concat("K")
    }else{
        replacePrice = String(price).slice(0, -3).concat(`.${String(price).slice(-3, -2)}K`)
    }
    return replacePrice;
  }
  
  render() {
    const { product, getProduct, category } = this.props;
    const { quantity, redirectToDetail, note } = this.state;
    let sumRating = 0;
    let count = 0;
    if (product.ratings && product.ratings.length > 0) {
      let totalRating = 0;
      product.ratings.map((item) => {
        count++ ;
        totalRating = totalRating + item.point;
        return 0;
      })
      sumRating = Math.round(totalRating / count);
    }
    if (redirectToDetail) {
      return <Redirect to={`/products/${product.id}`}></Redirect>
    }

    return (
      <div className="mt-4">
        <div className="single-product-wrap ProductItem">
          <div className="fix-img-div-new product-image">
            <Link to={`/products/${product.id}`} >
              <LazyLoadImage
                effect="blur"
                className="fix-img-new" 
                src={`${config.api.url_BE}/${product.image ? product.image : null}`} 
                alt={product.nameProduct}
              />
            </Link>
            {
              product.discountPrice ? <span className="sticker">{
                `-${(100 - 100*parseInt(product.discountPrice)/parseInt(product.price)).toFixed(0)}%`
              }</span> : null
            }
            <div className="watermark-price-container">
              <div className="background-watermark">
                <img src={WatermarkImage} alt="img"/>
                <div className="watermark-price">{ product.discountPrice ? this.convertPrice(product.discountPrice) : this.convertPrice(product.price)}</div>
              </div>
            </div>
          </div>
          <div className="product_desc">
            <div className="product_desc_info">
              <div className="product-review text-truncate">
                <h5 className="manufacturer">
                  <Link to={`/categories/${category.id}`} className="category-name text-truncate">
                    {
                      category && category.nameCategory 
                      ? category.nameCategory 
                      : null
                    }
                  </Link>
                </h5>
              </div>
              <h4><Link className="product_name text-truncate" to={`/products/${product.id}`}>{product.nameProduct}</Link></h4>
              <div className="price-box">
                {
                  product.discountPrice 
                  ? <div>
                    <span className="new-price new-price-2">{formatNumber.format(product.discountPrice)}</span>
                    <span className="old-price">{formatNumber.format((product.price))}</span>
                  </div>
                  : <span className="new-price new-price-2">{formatNumber.format(product.price)}</span>
                }
              </div>
            </div>
            <div className="add-actions">
              <ul className="add-actions-link">
                <li className="add-cart active" data-toggle="modal" data-target="#exampleModalCenter5"><Link to="#" onClick={() => this.getInfoProduct(product.id)} >Đặt hàng</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    getProduct: state.product
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProductDetail: (id) => {
      dispatch(actGetProductRequest(id))
    },
    addCart: (item, quantity, note) => {
      dispatch(actAddCartRequest(item, quantity, note))
    },
    fetch_products_other: (q, categoryId) => {
      dispatch(actFetchProductsOtherRequest(q, categoryId))
    },
    fetch_product_ratings: (id) => {
      dispatch(actFetchRatingsRequest(id));
    },
    addFavorite: (id, token) => {
      dispatch(actAddFavoriteRequest(id, token))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductByCateItem)

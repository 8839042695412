import React, { Component } from 'react'
import ShoppingCart from '../components/ShoppingCart/ShoppingCart'
import LinkHere from '../components/LinkHere/LinkHere'

export default class ShoppingCartPage extends Component {
  render() {

    return (
      <div>
        <LinkHere title="Giỏ hàng"></LinkHere>
        <ShoppingCart></ShoppingCart>
      </div>
    )
  }
}

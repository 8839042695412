import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { formatNumber } from '../../config/TYPE'
import { connect } from 'react-redux'
import { actGetProductRequest } from '../../redux/actions/products'
import { actFetchRatingsRequest, actAddFavoriteRequest } from '../../redux/actions/rating'
import { actAddCartRequest } from '../../redux/actions/cart'
import { startLoading, doneLoading } from '../../utils/loading'
import { toast } from 'react-toastify';
import {config} from 'config';
import './style.css'
toast.configure()

let token;
class ProductOtherItems extends Component {

  updateProductDetail = (id) => {
    this.props.getOneProduct(id);
    this.props.reload_ratings(id);
  }
  componentDidMount() {
    token = localStorage.getItem('_auth');
  }
  addItemToCart = async (product) => {
    startLoading()
    await this.props.addCart(product);
    doneLoading();
  }
  addItemToFavorite = (id) => {
    startLoading()
    if (!token) {
      return toast.error('Please login before add product to list favorites')
    }
    this.props.addFavorite(id, token);
    doneLoading();
  }
  getInfoProduct = (id) => {
    this.setState({
      quantity: 1,
      note: ''
    })
    this.props.getInfoProduct(id);
  }

  render() {
    const { product } = this.props;
    let sumRating = 0;
    let count = 0;
    if (product.rating && product.rating.length > 0) {
      let totalRating = 0;
      product.rating.map((item) => {
        count++ ;
        totalRating = totalRating + item.point;
        return 0;
      })
      sumRating = Math.round(totalRating / count);
    }
    return (
      <div className="pt-30">
        <div className="single-product-wrap">
          <div className="fix-img-div-other product-image">
            <Link onClick={(id) => this.updateProductDetail(product.id)} to={`/products/${product.id}`}>
              <img className="fix-img-other" src={`${config.api.url_BE}/${product.image}`} alt={product.nameProduct} />
            </Link>
            {
              product.discountPrice ? <span className="sticker">{
                `-${(100 - 100*parseInt(product.discountPrice)/parseInt(product.price)).toFixed(0)}%`
              }</span> : null
            }
          </div>
          <div className="product_desc">
            <div className="product_desc_info">
              <div className="product-review">
                <h5 className="manufacturer">
                  <Link to={`/categories/${product.categoryId_categories && product.categoryId_categories.length > 0 ? product.categoryId_categories[0].id : ''}`} className="category-name text-truncate">
                    {
                      product.categoryId_categories && product.categoryId_categories.length > 0 ? product.categoryId_categories[0].nameCategory : ''
                    }
                  </Link>
                </h5>
              </div>
              <h4><Link onClick={(id) => this.updateProductDetail(product.id)} className="product_name text-truncate" to={`/products/${product.id}`}>{product.nameProduct}</Link></h4>
              <div className="price-box">
                {
                  product.discountPrice
                  ? <div>
                    <span className="new-price new-price-2">{formatNumber.format(product.discountPrice)}</span>
                    <span className="old-price">{formatNumber.format((product.price))}</span>
                  </div>
                  : <span className="new-price new-price-2">{formatNumber.format(product.price)}</span>
                }
              </div>
            </div>
            <div className="add-actions">
              <ul className="add-actions-link">
                <li className="add-cart active" data-toggle="modal" data-target="#exampleModalCenter"><Link to="#" onClick={() => this.getInfoProduct(product.id)} >Đặt hàng</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getOneProduct: (id) => {
      dispatch(actGetProductRequest(id))
    },
    reload_ratings: (id) => {
      dispatch(actFetchRatingsRequest(id))
    },
    addCart: (item, quantity) => {
      dispatch(actAddCartRequest(item, quantity))
    },
    addFavorite: (id, token) => {
      dispatch(actAddFavoriteRequest(id, token))
    },
    getInfoProduct: (id) => {
      dispatch(actGetProductRequest(id))
    },
  }
}

export default connect(null, mapDispatchToProps)(ProductOtherItems)

import React from 'react'
// import PropTypes from 'prop-types'

import './NoticeDiscount.css';

function NoticeDiscount(props) {
    const {discount, style} = props;

    return (
            <div className="Notice-Discount" style ={style}>
                -{discount}%
            </div>
    )
}

NoticeDiscount.propTypes = {

}

export default NoticeDiscount


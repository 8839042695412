import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import ProductByCateItem from './ProductByCateItem'
import Slider from "react-slick";
import './style.css';
import ProductItem from '../../../common/ProductItemMobile/ProductItemMobile';

class ProductByCategory extends Component {

  render() {
    const { category } = this.props;
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 7,
      slidesToScroll: 5,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 4,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 3,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 760,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 3,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: false
          }
        }
      ]
    };

    const settingMobile = {
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 2,
      cssEase: "linear"
    }
    return (
      <section className="product-area li-laptop-product">
        <div className="container Product-category">
          <div className="row ">
            <div className="col-lg-12">
              <div className="li-section-title">
                <h2 className="title-category">
                  <Link to={`/categories/${category.id}`} className="category-name">{category.nameCategory}</Link>
                </h2>
              </div>
              <div className="tab-content mt-1 desktop">
                <div id="li-new-product">
                  <Slider {...settings}>
                    {
                      (category.products && category.products.length) ? category.products.map((product, index) => {
                          return (
                            <ProductByCateItem
                              key={index} 
                              product={product}
                              category = {category} 
                            ></ProductByCateItem>
                          )
                        }                 
                      ) : null
                    }
                  </Slider>
                </div>
                
              </div>
              <div className="Product-categories-mobile">
                <Slider {...settingMobile}>
                  {
                    (category.products && category.products.length) ? category.products.map((product, index) => {
                        return (
                          <ProductItem 
                            key={index} 
                            product={product} 
                            category = {category}
                          />
                        )
                      }                 
                    ) : null
                  }
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}


export default ProductByCategory;
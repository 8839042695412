import React, { Component } from 'react'
import ProductContent from './ProductContent'
import ProductsMobile from './ProductsMobile';
import LazyLoad from 'react-lazyload';
import Spinner from 'components/common/Spinner/Spinner';

export default class Product extends Component {
  render() {
    return (
      <div className="product-area Product">
        <LazyLoad
          placeholder={<Spinner className="container product-area Product"></Spinner>}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="li-product-tab">
                  <ul className="nav li-product-menu">
                    <li><a className="active" data-toggle="tab" href="#li-new-product"><span>Sản phẩm vừa đăng</span></a></li>
                  </ul>
                </div>
              </div>
            </div>
            {/* Begin item Content Area */}
            <ProductContent></ProductContent>
            <ProductsMobile />
            {/* End item Content Area */}
          </div>
        </LazyLoad>
      </div>
    )
  }
}

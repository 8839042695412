import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { actAddRatingRequest } from "../../redux/actions/rating";
import { toast } from "react-toastify";
toast.configure();

let token;
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "500px"
  }
};

// const mailUrl = "https://limupa-shop-client.herokuapp.com/products/";
class ProductDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ratingPoint: 0,
      modalIsOpen: false,
      textRating: "",
      viewAllRating: false
    };
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    token = localStorage.getItem("_auth");
  }  
  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    this.subtitle.style.color = "#2d3136";
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  handleChangeRating = value => {
    this.setState({
      ratingPoint: value
    });
  };

  handleChange = event => {
    let name = event.target.name;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState({
      [name]: value
    });
  };

  handleSubmitViewAllRating = () => {
    const { viewAllRating } = this.state;
    this.setState({
      viewAllRating: !viewAllRating
    });
  };

  handleSubmitRating = async (event, productId) => {
    event.preventDefault();
    const { ratingPoint, textRating } = this.state;
    if (!ratingPoint) {
      return toast.error("Chọn số điểm trước khi gửi đánh giá");
    }
    const newTextRating = textRating ? textRating : null;
    const data = {
      point: ratingPoint,
      content: newTextRating
    };
    if (!token) {
      return toast.error("Đăng nhập trước khi đánh giá");
    }
    this.props.add_rating(productId, data, token);
    this.setState({
      modalIsOpen: false,
      ratingPoint: 0
    });
  };

  render() {
    const { product, productRatings } = this.props;
    const { ratingPoint } = this.state;
    let count = 0; //co bao nhieu danh gia
    let totalRating = 0; // xem danh gia bang bao nhieu sau do / count tinh trung binh
    let fixRating = 0; //rating fix cung left sider bar
    let showFixRating = 0; // show ***** tren left silde bar
    let oneStart = 0;
    let twoStart = 0;
    let threeStart = 0;
    let fourStart = 0;
    let fiveStart = 0;
    let showOneStart = 0;
    let showTwoStart = 0;
    let showThreeStart = 0;
    let showFourStart = 0;
    let showFiveStart = 0;
    let showProductRating = [];
    if (productRatings && productRatings.length > 0) {
      productRatings.forEach(item => {
        if (item.point === 1) {
          oneStart++;
        }
        if (item.point === 2) {
          twoStart++;
        }
        if (item.point === 3) {
          threeStart++;
        }
        if (item.point === 4) {
          fourStart++;
        }
        if (item.point === 5) {
          fiveStart++;
        }
        count++;
        totalRating = totalRating + item.point;
      });
      fixRating = (totalRating / count).toFixed(1);
      showFixRating = Math.round(fixRating);
      showOneStart = ((oneStart / count) * 100).toFixed(0);
      showTwoStart = ((twoStart / count) * 100).toFixed(0);
      showThreeStart = ((threeStart / count) * 100).toFixed(0);
      showFourStart = ((fourStart / count) * 100).toFixed(0);
      showFiveStart = ((fiveStart / count) * 100).toFixed(0);
    }
    showProductRating = productRatings.slice(0, 4);
    return (
      <div className="product-area pt-40">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="li-product-tab product-longdesc-title">
                <ul className="nav li-product-menu">
                  <li>
                    <a className="active" data-toggle="tab" href="#description">
                      <span>Mô tả</span>
                    </a>
                  </li>
                </ul>
              </div>
              {/* Begin Li's Tab Menu Content Area */}
            </div>
          </div>
          <div className="tab-content">
            <div
              id="description"
              className="tab-pane active show"
              role="tabpanel"
            >
              <div className="product-description">
                <Link to={`/post/${product.id}`}>LINK MÔ TẢ CHI TIẾT SẢN PHẨM</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    product: state.product,
    productRatings: state.productRatings
  };
};

const mapDispatchToProps = dispatch => {
  return {
    add_rating: (productId, rating, token) => {
      dispatch(actAddRatingRequest(productId, rating, token));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDescription);

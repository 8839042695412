import React, { Component } from 'react'
import ProductItem from './ProductItem'
import ProductItemMobile from '../common/ProductItemMobile/ProductItemMobile';
import ProductItemFlex from '../common/ProductItemFlex/ProductItemFlex';
import { connect } from 'react-redux'
import MuiAlert from '@material-ui/lab/Alert';
import { actFetchProductsRequest, actSearchProductsRequest, actFetchMoreSearchProductsRequest } from '../../redux/actions/products';
import InfiniteScroll from "react-infinite-scroll-component";

class ProductSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      sort: '-createAt',
      isGrid: true,
      isHasMore: true
    };
  }

  componentDidMount() {
    this.fetch_reload_data();
  }

  fetch_reload_data(){
    this.props.searchProduct(this.props.keyWord);
  }
  
  fetchMoreProductSearch(){
    const {products, keyWord} = this.props;
    const {currentPage, sort} = this.state; 
    const limit = 12;

    const offset = limit * currentPage;
    if( (Math.floor(products.total/limit) +1) > currentPage) {
      this.setState({
        currentPage: currentPage + 1
      })
      this.props.fetch_moreProductSearch(keyWord, sort, offset);
    } 
    if(Math.floor(products.total/limit) === currentPage) {
      this.setState({
        isHasMore: false
      })
    }
  }

  handleChangeSelectSort = (event) => {
    const offset = 0;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({
      // total: res.total,
      sort: value,
      currentPage: 1,
      isHasMore: true
    });
    this.props.searchProduct(this.props.keyWord, value, offset);
  }

  handleChangeView = () => {
    this.setState({
      isGrid: !this.state.isGrid
    })
  }
  
  render() {
    let { products} = this.props;
    const {isHasMore} = this.state;

    return (
      <div className="content-wraper Products-search">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <InfiniteScroll
                dataLength={products.results && products.results.length || 12}
                next={this.fetchMoreProductSearch.bind(this)}
                hasMore={products.total && products.total > 12 ? isHasMore : false}
                loader={<p style={{textAlign:'center'}}>Loading...</p>}
              >
                {/* Begin Li's Banner Area */}
                <div className="single-banner shop-page-banner">
                  <a href="/">
                    <img src="/images/bg-banner/2.jpg" alt="Li's Static Banner" />
                  </a>
                </div>
              
                {/* Li's Banner Area End Here *presentation/}
                {/* shop-top-bar start */}
                <div className="shop-top-bar mt-30">
                  <div className="shop-bar-inner">
                    <div className="product-view-mode">
                      {/* shop-item-filter-list start */}
                      <ul className="nav shop-item-filter-list" role="tablist">
                        <li className="active desktop" role="presentation"><a aria-selected="true" className="active show" data-toggle="tab" role="tab" aria-controls="grid-view" ><i className="fa fa-th" /></a></li>
                        <li className="active mobile-flex" onClick={this.handleChangeView} role="presentation"><a aria-selected="true" className="active show" data-toggle="tab" role="tab" aria-controls="grid-view" ><i className="fa fa-th" /></a></li>
                      </ul>
                      {/* shop-item-filter-list end */}
                    </div>
                  </div>
                  {/* product-select-box start */}
                  <div className="product-select-box">
                    <div className="product-short">
                      <p>Sắp xếp:</p>
                      <select className="nice-select" onChange={this.handleChangeSelectSort} >
                        <option value="createdAt">Tất cả</option>
                        <option value="nameProduct">Tên (A - Z)</option>
                        <option value="-nameProduct">Tên (Z - A)</option>
                        <option value="price">Giá (Thấp &gt; Cao)</option>
                        <option value="-price">Giá (Cao &gt; Thấp)</option>
                      </select>
                    </div>
                  </div>
                  {/* product-select-box end */}
                </div>
                {/* shop-top-bar end */}
                {/* shop-products-wrapper start */}
                <div className="shop-products-wrapper">
                  <div className="tab-content">
                    <div id="grid-view" className=" tab-pane fade active show" role="tabpanel">
                      <div className="product-area shop-product-area">
                        <div className="row desktop">
                          {
                            products.results && products.results.length ? products.results.map((item, index) => {
                              return (
                                <ProductItem key={index} product={item} ></ProductItem>
                              )
                            }) : null
                          }
                        </div>
                        {
                          this.state.isGrid 
                          ? <div className="grid-view-mobile mobile-grid">
                            {/* Khu vực grid 3 colum */}
                            {products.results && products.results.map((product, index) => {
                              return (
                                <ProductItemMobile
                                  key={index} 
                                  product={product} 
                                  category = {
                                    {
                                        'id': product.categoryId_categories && product.categoryId_categories.length > 0 ? product.categoryId_categories[0].id : '', 
                                        'nameCategory': product.categoryId_categories && product.categoryId_categories.length > 0 ? product.categoryId_categories[0].nameCategory : ''
                                    }
                                  }  
                                />
                              )
                            })}
                          </div>
                          : <div className="mobile-flex flex-view-mobile">
                            {/* Khu vực product view flex */}
                            {products.results && products.results.map((product, index) => {
                              return (
                                <ProductItemFlex 
                                  key={index} 
                                  product={product} 
                                  category = {
                                    {
                                        'id': product.categoryId_categories && product.categoryId_categories.length > 0 ? product.categoryId_categories[0].id : '', 
                                        'nameCategory': product.categoryId_categories && product.categoryId_categories.length > 0 ? product.categoryId_categories[0].nameCategory : ''
                                    }
                                  }  
                                />
                              )
                            })}
                          </div> 
                        }
                      </div>
                    </div>
                    {
                      !products.total && <MuiAlert style={{marginTop:"10px"}} severity="info">Không có sản phẩm trùng khớp</MuiAlert>
                    } 
                  </div>
                </div>
              </InfiniteScroll> 
            </div>
            {/* shop-products-wrapper end */}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.productsSearch
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetch_products: (value) => {
      dispatch(actFetchProductsRequest(value));
    },
    searchProduct: (q, value, offset) => {
      return dispatch(actSearchProductsRequest(q, value, offset))
    },
    fetch_moreProductSearch: (q, value, offset) => {
      return dispatch(actFetchMoreSearchProductsRequest(q, value, offset))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSearch)

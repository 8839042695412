import React, { Component } from "react";
import ProductItem from "./ProductItem";
import { connect } from "react-redux";
import { actFetchProductsRequest, actFetchMoreAllProducts } from "../../redux/actions/products";
import ProductItemMobile from '../common/ProductItemMobile/ProductItemMobile';
import ProductItemFlex from '../common/ProductItemFlex/ProductItemFlex';
import InfiniteScroll from "react-infinite-scroll-component";

class ProductAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      currentPage: 1,
      sort: 'createAt',
      isGrid: true,
      isHasMore: true
    };
  }

  componentDidMount() {
    this.fetch_reload_data();
  }

  fetch_reload_data(){
    this.props.fetch_products().then(res => {
      this.setState({
        total: res.total
      });
    }).catch(err => {
      console.log(err);  
    })
  }

  fetchMoreData(){
    const {currentPage, total} = this.state;
    const {products} = this.props;

    const limit = 12;
    const offset = limit * (currentPage);
    if( (Math.floor(total/limit) +1) > currentPage) {
      this.setState({
        currentPage: currentPage + 1
      })
      this.props.fetch_moreDate(this.state.sort, offset);
    } 
    if(Math.floor(total/limit) === currentPage) {
      this.setState({
        isHasMore: false
      })
    }
  }

  handleChangeSelectSort = event => {
    const offset = 0;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState({
      // total: res.total,
      sort: value,
      currentPage: 1,
      isHasMore: true
    });
    this.props.fetch_products(value, offset)
  };

  handleChangeView = () => {
    this.setState({
      isGrid: !this.state.isGrid
    })
  }

  render() {
    let { products } = this.props;
    const {isHasMore, total} = this.state;

    return (
      <div className="content-wraper ProductAll">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <InfiniteScroll
                dataLength={products.length && products.length || 12}
                next={this.fetchMoreData.bind(this)}
                hasMore={total && total > 12 ? isHasMore : false}
                loader={<p style={{textAlign:'center'}}>Loading...</p>}
              >
                {/* Begin Li's Banner Area */}
                <div className="single-banner shop-page-banner">
                  <a href="/">
                    <img
                      src="/images/bg-banner/2.jpg"
                      alt="Li's Static Banner"
                    />
                  </a>
                </div>
                {/* Li's Banner Area End Here *presentation/}
                {/* shop-top-bar start */}
                <div className="shop-top-bar mt-30">
                  <div className="shop-bar-inner">
                    <div className="product-view-mode">
                      {/* shop-item-filter-list start */}
                      <ul className="nav shop-item-filter-list" role="tablist">
                        <li className="active" role="presentation">
                          <a
                            aria-selected="true"
                            className="active show desktop"
                            data-toggle="tab"
                            role="tab"
                            aria-controls="grid-view"
                          >
                            <i className="fa fa-th" />
                          </a>
                        </li>
                        <li className="active mobile-flex" onClick={this.handleChangeView} role="presentation"><a aria-selected="true" className="active show" data-toggle="tab" role="tab" aria-controls="grid-view" ><i className="fa fa-th" /></a></li>
                      </ul>
                      {/* shop-item-filter-list end */}
                    </div>
                    <div className="toolbar-amount">
                      {/* <span>Hiên thị 12 sản phẩm</span> */}
                    </div>
                  </div>
                  {/* product-select-box start */}
                  <div className="product-select-box">
                    <div className="product-short">
                      <p>Sắp xếp:</p>
                      <select
                        className="nice-select"
                        onChange={this.handleChangeSelectSort}
                      >
                        <option value="createAt">Tất cả</option>
                        <option value="nameProduct">Tên (A - Z)</option>
                        <option value="-nameProduct">Tên (Z - A)</option>
                        <option value="price">Giá (Thấp &gt; Cao)</option>
                        <option value="-price">Giá (Cao &gt; Thấp)</option>
                      </select>
                    </div>
                  </div>
                  {/* product-select-box end */}
                </div>
                {/* shop-top-bar end */}
                {/* shop-products-wrapper start */}
                <div className="shop-products-wrapper">
                  <div className="tab-content">
                    <div
                      id="grid-view"
                      className="tab-pane fade active show"
                      role="tabpanel"
                    >
                      <div className="product-area shop-product-area">
                        <div className="row desktop">
                          {products && products.length
                            ? products.map((item, index) => {
                                return (
                                  <ProductItem
                                    key={index}
                                    product={item}
                                  ></ProductItem>
                                );
                              })
                            : null}
                        </div>
                        {
                          this.state.isGrid 
                          ? <div className="grid-view-mobile mobile-grid">
                            {/* Khu vực grid 3 colum */}
                            {products && products.length && products.map((product, index) => {
                              return (
                                <ProductItemMobile 
                                  key={index} 
                                  product={product} 
                                  category = {{
                                    'id': product.categoryId_categories && product.categoryId_categories.length > 0 ? product.categoryId_categories[0].id : '',
                                    'nameCategory': product.categoryId_categories && product.categoryId_categories.length > 0 ? product.categoryId_categories[0].nameCategory : ''
                                  }}
                                />
                              )
                            })}
                          </div>
                          : <div className="mobile-flex flex-view-mobile">
                            {/* Khu vực product view flex */}
                            {products &&  products.length && products.map((product, index) => {
                              return (
                                <ProductItemFlex 
                                  key={index} 
                                  product={product} 
                                  category = {{
                                    'id': product.categoryId_categories && product.categoryId_categories.length > 0 ? product.categoryId_categories[0].id : '',
                                    'nameCategory': product.categoryId_categories && product.categoryId_categories.length > 0 ? product.categoryId_categories[0].nameCategory : ''
                                  }}
                                />
                              )
                            })}
                          </div> 
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </InfiniteScroll> 
            </div>
            {/* shop-products-wrapper end */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    products: state.products
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetch_products: (value, offset) => {
      return dispatch(actFetchProductsRequest(value, offset));
    },
    fetch_moreDate: (value, offset) => {
      return dispatch(actFetchMoreAllProducts(value, offset))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductAll);

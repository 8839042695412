import React, { Component } from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatNumber } from '../../../../config/TYPE';
import { connect } from 'react-redux'
import parse from 'html-react-parser';
import { actFetchRatingsRequest, actAddFavoriteRequest } from '../../../../redux/actions/rating';
import { actGetProductRequest, actFetchProductsOtherRequest } from '../../../../redux/actions/products';
import { actAddCartRequest } from '../../../../redux/actions/cart';
import { startLoading, doneLoading } from '../../../../utils/loading'
import '../style.css'
import {config} from 'config'
toast.configure()

class ProductModal extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      offset: 0,
      quantity: 1,
      note: '',
      redirectToDetail: false
    }
    this.handleClose = React.createRef(null);
  }

  upItem = (quantity) => {
    this.setState({
      quantity: quantity + 1
    })
  }

  downItem = (quantity) => {
    if (quantity <= 1) {
      return
    }
    this.setState({
      quantity: quantity - 1
    })
  }

  handleChange = event => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({
      [name]: value
    });
  }

  handleChangeNumber = async (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({
        quantity: e.target.value
      })
    }
  }

  getInfoProduct = (id) => {
    this.setState({
      quantity: 1,
      note: ''
    })
    this.props.getProductDetail(id);
  }

  addItemToCart2 = (event, product) => {
    event.preventDefault();
    const { quantity, note } = this.state;
    if(quantity <= 0) {
      toast.error('Số lượng tối thiểu là 1');
      this.setState({
        quantity: 1
      })
    } else {
      startLoading()
      this.props.addCart(product, quantity, note);
      doneLoading();
      this.handleClose.current.click();
      this.setState({
        quantity: 1,
        note: ''
      })
    }
  }
  
  render() {
    const { getProduct } = this.props;
    const { quantity, note } = this.state;

    return (
        <div className="modal fade modal-wrapper" id="exampleModalCenter5">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content"> 
              <div className="modal-body">
                <button ref={this.handleClose} type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
                <div className="modal-inner-area row">
                  <div className="col-lg-5 col-md-6 col-sm-6">
                    {/* Product Details Left */}
                    <div className="product-details-left">
                      <div className="product-details-images slider-navigation-1">
                        <div className="lg-image">
                          <img src={`${config.api.url_BE}/${getProduct.image}`} alt={getProduct.nameProduct} />
                        </div>
                      </div>
                    </div>
                    {/* // Product Details Left */}
                  </div>
                  <div className="col-lg-7 col-md-6 col-sm-6">
                    <div className="product-details-view-content">
                      <div className="product-info">
                        <h2>{getProduct.nameProduct}</h2>
                        <div className="rating-box">
                        </div>
                        <div className="price-box pt-20">
                          {
                            getProduct.discountPrice 
                            ? <div>
                              <span className="new-price new-price-2">{formatNumber.format(getProduct.discountPrice)}</span>
                              <span className="old-price">{formatNumber.format((getProduct.price))}</span>
                            </div>
                            : <span className="new-price new-price-2">{formatNumber.format(getProduct.price)}</span>
                          }
                        </div>
                        <div className="product-desc">
                          <p>
                            <span>{getProduct.description && parse(getProduct.description)}
                            </span>
                          </p>
                        </div>
                        <div className="product-variants">
                        </div>
                        <div className="single-add-to-cart">
                          <form className="cart-quantity" onSubmit={(event) => this.addItemToCart2(event, getProduct)}>
                            <div>
                              <label>Ghi chú sản phẩm (Tùy chọn)</label>
                              <textarea
                                placeholder="Ghi rõ số lượng sản phẩm nếu cùng chung số lượng sản phẩm, vd: 10xmax + 5 samsung,..."
                                onChange={this.handleChange}
                                name="note"
                                value={note}
                              />
                            </div>
                            <div className="modal-total-price">
                              {
                                getProduct.discountPrice
                                ? <span>Tổng tiền: { formatNumber.format(getProduct.discountPrice*quantity) }</span>
                                : <span>Tổng tiền: { formatNumber.format(getProduct.price*quantity) }</span> 
                              }
                            </div>
                            
                            <div className="quantity">
                              <label>Số lượng</label>
                              <div className="cart-plus-minus">
                                <input type="text"
                                  className="cart-plus-minus-box"
                                  name={quantity}
                                  value={quantity}
                                  onChange={this.handleChangeNumber}
                                />
                                <div onClick={() => this.downItem(quantity)} className="dec qtybutton"><i className="fa fa-angle-down" /></div>
                                <div onClick={() => this.upItem(quantity)} className="inc qtybutton"><i className="fa fa-angle-up" /></div>
                              </div>
                            </div>
                            <button className="add-to-cart" type="submit">Đặt hàng</button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          
            </div>
          </div>
        </div> 
    )
  }
}

const mapStateToProps = (state) => {
  return {
    getProduct: state.product
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProductDetail: (id) => {
      dispatch(actGetProductRequest(id))
    },
    addCart: (item, quantity, note) => {
      dispatch(actAddCartRequest(item, quantity, note))
    },
    fetch_products_other: (q, categoryId) => {
      dispatch(actFetchProductsOtherRequest(q, categoryId))
    },
    fetch_product_ratings: (id) => {
      dispatch(actFetchRatingsRequest(id));
    },
    addFavorite: (id, token) => {
      dispatch(actAddFavoriteRequest(id, token))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductModal)

import React, { Component } from 'react'
import PostDetail from 'components/PostDetail/PostDetail';

export default class PostPage extends Component {

  render() {
    const {idProduct} = this.props.match.match.params
    return (
      <div>
        <PostDetail id={idProduct}></PostDetail>
      </div>
    )
  }
}


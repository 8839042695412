import React from "react";
import Carousel from "react-elastic-carousel";
import { Link } from "react-router-dom";
import { actGetProductOfCategoryRequest } from '../../redux/actions/products';
import {actFetchLocation} from '../../redux/actions/location'
import { useSelector, useDispatch } from "react-redux";
import "./SliderCategories.css";

const breakPoints = [
  { width: 300, itemsToShow: 3 },
  { width: 400, itemsToShow: 4 },
  { width: 500, itemsToShow: 5 },
];

const SliderCategories = (props) => {
  const categories = useSelector(state => state.categories);
  const location = useSelector(state => state.location);
  const dispath = useDispatch();

  const onClick = (id) => {
    return () => {
      const action = actGetProductOfCategoryRequest(id, '-createAt');
      const action1 = actFetchLocation(id);
      dispath(action)
      dispath(action1);
    }
  }

  return (
    <div className="SliderCategories">
      <Carousel
        breakPoints={breakPoints}
        pagination={false}
        showArrows={false}
        outerSpacing={0}
      >
        {
          location === -1
          ? <Link 
            to="/" 
            className="SC-all"
            onClick={onClick(-1)}
          >
            <div className="isFocus">Tất cả</div>
            <div className="SC-border"></div>
          </Link>
          :<Link 
            to="/" 
            className="SC-all"
            onClick={onClick(-1)}
          >
            <div className="SC-link SC-item">Tất cả</div>
          </Link>
        }
        {
          categories.map((category) => {
            if(parseInt(category.id) === parseInt(location)){
              return (
                <Link 
                  to={`/categories/${category.id}`} 
                  className="SC-all"
                  onClick={onClick(category.id)}
                  key={category.id}
                >
                  <div className="isFocus">{category.nameCategory}</div>
                  <div className="SC-border"></div>
                </Link>
              )
            } else{
              return (
                <Link 
                  to={`/categories/${category.id}`} 
                  className="SC-all"
                  onClick={onClick(category.id)}
                  key={category.id}
                >
                  <div className="SC-link SC-item">{category.nameCategory}</div>
                </Link>
              )
            }
          })
        }
      </Carousel>
    </div>
  );
};

export default SliderCategories;

import React, { Component } from 'react'
import { actLoginRequest } from '../../redux/actions/auth';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHA from "react-google-recaptcha";
import { startLoading, doneLoading } from '../../utils/loading'
import './style.css';
toast.configure()

class Login extends Component {

  constructor(props) {
    super(props);
    // this.verifyCallback = this.verifyCallback.bind(this);
    this.state = {
      email: "",
      isVerified: false
    }
  }

  handleChange = event => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({
      [name]: value
    });
  }

  recaptchaLoaded(){
    //reload captcha
  }

  handleSubmit = async event => {
    event.preventDefault();
    const { email, isVerified } = this.state;
    //verifi captcha
    if(!isVerified) {
      return toast.error('Bạn chưa xác nhận mã capcha!');
    }
    const user = { email }
    startLoading();
    await this.props.loginRequest(user);
    doneLoading();
  }

  onChangeCapcha = (value) => {
    if(value) {
      this.setState({
        isVerified: true
      })
    }
  }

  render() {
    const { email} = this.state;
    const { user } = this.props;

    if (user !== null ) {
      return <Redirect to="/history-booking"></Redirect>
    }
    
    return (
      <div className="col-sm-12 col-md-12 col-xs-12 form-login">
        {/* Login Form s*/}
        <form onSubmit={(event) => this.handleSubmit(event)}>
          <div className="login-form">
            <h4 className="login-title">Đăng nhập</h4>
            <div className="row">
              <div className="col-md-12 col-12 mb-20">
                <label>Số điện thoại hoặc Email*</label>
                <input 
                  onChange={this.handleChange} 
                  value={email} 
                  className="mb-0" 
                  type="text" 
                  placeholder="Số điện thoại hoặc Email" 
                  name='email' 
                />
              </div>
              <div className="col-md-4">
                <button className="register-button mt-0 mb-3">Đăng nhập</button>
              </div>
              <div className="col-md-12">
                <div className = "container-action-checkout">
                  <ReCAPTCHA
                    ref={e => (this.captcha = e)}
                    sitekey="6LcRg8gdAAAAABATNGd_JmIyH8DN78iPxngHqXYp"
                    onChange={this.onChangeCapcha}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginRequest: (user) => {
      dispatch(actLoginRequest(user))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)

import React, {useState} from "react";
import { Link } from "react-router-dom";
import {actFetchLocation} from '../../redux/actions/location'
import { useSelector, useDispatch } from "react-redux";
import {
  AiOutlineHome,
  AiOutlineShoppingCart,
  AiFillDatabase
} from "react-icons/ai";
import { FaShippingFast } from 'react-icons/fa';
import Badge from '@material-ui/core/Badge';

import "./BottomPanel.css";

const BottomPanel = () => {
  const [indexFocus, setIndexFocus] = useState(1);
  const cart = useSelector(state => state.cart)
  const dispath = useDispatch();
  
  const onClick = (index) => {
    return () => {
      setIndexFocus(index);
      const action = actFetchLocation(-1);
      dispath(action)
    }
  }

  return (
    <div className="bottomPanel">
      <Link to="/" onClick={onClick(1)}>
        <div className= {indexFocus === 1 ? "bottomPanel__items focus_icon" : "bottomPanel__items"} >
          <AiOutlineHome className="bottomPanel__icons" />
          <span>Trang chủ</span>
        </div>
      </Link>
      <Link to="/products" onClick={onClick(2)}>
        <div className= {indexFocus === 2 ? "bottomPanel__items focus_icon" : "bottomPanel__items"} >
          <AiFillDatabase className="bottomPanel__icons" />
          <span>Sản phẩm</span>
        </div>
      </Link>
      <Link to="/history-booking" onClick={onClick(3)}>
        <div className= {indexFocus === 3 ? "bottomPanel__items focus_icon" : "bottomPanel__items"}>
          <FaShippingFast className="bottomPanel__icons" />
          <span>Đơn hàng</span>
        </div>
      </Link>
      <Link to="/cart" onClick={onClick(4)}>
        <div  className= {indexFocus === 4 ? "bottomPanel__items focus_icon" : "bottomPanel__items"}>
          <Badge badgeContent={cart.length > 0 ? cart.length : 0}>
            <AiOutlineShoppingCart className="bottomPanel__icons" />
          </Badge>
          <span>Giỏ hàng</span>
        </div>
      </Link>
    </div>
  );
};

export default BottomPanel;

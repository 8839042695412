import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './style.css';

export default class LinkHere extends Component {
  render() {
    const { title } = this.props;
    return (
      /*<div className="breadcrumb-area">*/
         <div className="container">
         <div className="LinkHeader-body">
           
          </div>
        </div>
     /* </div>*/
    )
  }
}

import React, { Component } from 'react'
import TrenddingProductItems from './TrenddingProductItems'
import { connect } from 'react-redux'
import { actFetchProductsOfficeRequest } from '../../../../redux/actions/products';
import ProductMobileItem from '../../../common/ProductItemMobile/ProductItemMobile';
import Slider from "react-slick";
import './style.css'
import LazyLoad from 'react-lazyload';
import Spinner from 'components/common/Spinner/Spinner';

class TrenddingProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0
    }
  }

  componentDidMount() {
    const { offset } = this.state;
    this.props.fetch_products_new(offset);
  }

  render() {
    const { products } = this.props;
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 7,
      slidesToScroll: 5,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 4,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 760,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        }
      ]
    };

    return (
      <section className="product-area li-trending-product Trendding-products">
        <LazyLoad
          placeholder={<Spinner className="container product-area Product"></Spinner>}
        >
          <div className="container">
            <div className="row">
              {/* Begin Li's Tab Menu Area */}
              <div className="col-lg-12">
                <div className="li-product-tab li-trending-product-tab">
                  <h2>
                    <span>Sản phẩm tiêu biểu</span>
                  </h2>
                </div>
                {/* Begin Li's Tab Menu Content Area */}
                <div className=" desktop tab-content li-tab-content li-trending-product-content">
                  <div id="home1" className="tab-pane show fade in active">
                    {/* <div className="row"> */}
                    <Slider {...settings}>
                      {products && products.length ? products.map((product, index) => {
                        return (
                          <div key={index} className="pt-3">
                            <TrenddingProductItems product={product} ></TrenddingProductItems>
                          </div>
                        )
                      }) : null
                      }
                    </Slider>
                    {/* </div> */}
                  </div>
                </div>
                {/* Tab Menu Content Area End Here */}
                {/* Trendding products mobile */}
                <div className="products-mobile mobile-block">
                  <Slider {...settings}>
                    {products && products.length ? products.map((product, index) => {
                      return (
                        <ProductMobileItem 
                          product={product} 
                          key={index} 
                          category = {{
                            'id': product.categoryId_categories && product.categoryId_categories.length > 0 ? product.categoryId_categories[0].id : '',
                            'nameCategory': product.categoryId_categories && product.categoryId_categories.length > 0 ? product.categoryId_categories[0].nameCategory : ''
                          }}
                        />
                      )
                    }) : null
                    }
                  </Slider>
                </div>
              </div>
              {/* Tab Menu Area End Here */}
            </div>
          </div>
        </LazyLoad>
      </section>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.productsOffice
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetch_products_new: (offset) => {
      dispatch(actFetchProductsOfficeRequest(offset))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrenddingProduct)

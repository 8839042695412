import React from 'react';

import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

import { formatNumber } from '../../../config/TYPE'

import './CartItemMobile.css';

const CartItemMobile = (props) => {
    const {item} = props;
    return (
        <div className="ItemHistory">
            <div className="conatiner-name-totalPrice">
                <Link to={`/products/${item.product.id}`} className="cart-item-mobile-name">{item.nameProduct}</Link>
                <span className="totalPrice-moile">
                    {
                        item.product.discountPrice 
                        ? formatNumber.format(item.product.discountPrice * item.quantity)
                        : formatNumber.format(item.product.price * item.quantity)
                    }
                </span>
            </div>
            {/* Edit */}
            {
                item.product.categoryId_categories &&
                <div className="cart-categories">
                {
                    item.product.categoryId_categories.map((category, index) => {
                    if(index === item.product.categoryId_categories.length - 1) {
                        return <Link to={`/categories/${category.id || ''}`} key={index} className="cart-item-mobile-categoryName">{ category.nameCategory || ''}</Link>
                    }
                    return <Link to={`/categories/${category.id || ''}`} key={index} className="cart-item-mobile-categoryName">{`${category.nameCategory}, ` || ''}</Link>
                    })
                }
                </div>
            }
            {/* end */}
            {
                item.product.discountPrice 
                ? <div>
                    <span>Giá bán: </span>
                    <span className="cart-item-price">{formatNumber.format(item.product.discountPrice)}</span>
                    <span className="linethrought">{formatNumber.format(item.product.price)}</span>
                </div>
                :<span className="cart-item-price">Giá bán: {formatNumber.format(item.product.price)}</span>
            }
            <div className="container-note">
                <p>Ghi chú sản phẩm (Tùy chọn)</p>
                <p>{item.note}</p>
            </div>
            <div className="cart-amount">
                <p>Số lượng: {item.quantity}</p>
            </div>
        </div>
    );
};


CartItemMobile.propTypes = {

};


export default CartItemMobile;

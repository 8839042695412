import React,{useState}  from 'react';
import Drawer from '@material-ui/core/Drawer';
import { useDispatch } from 'react-redux';
import {Link} from 'react-router-dom';
import parse from 'html-react-parser';
import { actAddCartRequest } from '../../../redux/actions/cart';
import { startLoading, doneLoading } from '../../../utils/loading'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {config} from 'config';
import NoticeDiscount from '../NoticeDiscount/NoticeDiscount';

import './ProductModelMobile.css';

function ProductModalMobile(props) {
    const {product, open, handleClose} = props;

    const Body = (props) => {
        const {product, handleClose} = props;
        const [number, setNumber] = useState(1);
        const [totalPrice, setTotalPrice] = useState(product.discountPrice ? product.discountPrice : product.price);
        const [note, setNote] = useState('');
        const dispath = useDispatch();
        
        const handleChange = (e) => {
            const re = /^[0-9\b]+$/;
            if (e.target.value === '' || re.test(e.target.value)) { 
                setNumber(e.target.value)
                if(product.discountPrice){
                    setTotalPrice(product.discountPrice * e.target.value)
                }else{
                    setTotalPrice(product.price * e.target.value)
                }
            }
        }

        const upItem = (quantity) => {
            setNumber(quantity + 1)
            if(product.discountPrice){
                setTotalPrice(product.discountPrice * (quantity + 1))
            }else{
                setTotalPrice(product.price * (quantity + 1))
            }
        }
        
        const downItem = (quantity) => {
            if (quantity <= 1) {
                return
            }
            setNumber(quantity - 1)
            if(product.discountPrice){
                setTotalPrice(product.discountPrice * (quantity - 1))
            }else{
                setTotalPrice(product.price * (quantity - 1))
            }
        }

        const handleAddToCart = (product) => {
            return () => {
                if(number <= 0){
                    toast.error('Số lượng tối thiểu là 1');
                    setNumber(1)
                } else {
                    const action = actAddCartRequest(product, number, note);
                    startLoading()
                    dispath(action);
                    doneLoading();
                    handleClose();
                }
            }
        }

        const handleChangeNote = (e) => {
            console.log(e.target.value)
            setNote(e.target.value);
        }

        return (
            <div className="Modal-Mobile">
                {product && 
                    <div className="MM-content">
                        <Link to={`/products/${product.id}`} className="MM-img">
                            <img 
                                src = {`${config.api.url_BE}/${product.image}`} 
                                alt={product.nameProduct}
                            />
                            {
                                product.discountPrice
                                ? <NoticeDiscount
                                    style = {{top:"0", left:"0"}}
                                    discount={(100 - 100*parseInt(product.discountPrice)/parseInt(product.price)).toFixed(0)}
                                />
                                : null
                            }
                        </Link>
                        <div className="MM-info">
                            <Link to={`/products/${product.id}`} className="MM-name">{product.nameProduct}</Link>
                            {
                                product.discountPrice
                                ? <div className="LPIM-PriceContainer">
                                    <p className="LPIM-Linethrought">{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(product.price)}</p>
                                    <p className="LPIM-RPrice">{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(product.discountPrice)}</p>
                                </div>
                                : <p className="LPI-price">{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(product.price)}</p>
                            }
                            <div className="M-totalPrice">
                                <span>Tổng tiền: {totalPrice.toLocaleString('vi-VN', {style: 'currency', currency: 'VND'})}</span>
                            </div>
                            <div className="M-quantity">
                                <label>Số lượng</label>
                                <div className="cart-plus-minus">
                                    <input type="text"
                                    className="cart-plus-minus-box"
                                    name={number}
                                    value={number}
                                    onChange={handleChange}
                                    />
                                    <div onClick={() => downItem(number)} className="dec qtybutton"><i className="fa fa-angle-down" /></div>
                                    <div onClick={() => upItem(number)} className="inc qtybutton"><i className="fa fa-angle-up" /></div>
                                </div>
                            </div>
                            <button className="M-add-to-cart" onClick={handleAddToCart(product)}>Đặt hàng</button>
                        </div>
                    </div>
                }
                <div className="note-product-model">
                    <label>Ghi chú sản phẩm (Tùy chọn)</label>
                    <textarea
                        placeholder="Ghi rõ số lượng sản phẩm nếu cùng chung số lượng sản phẩm, vd: 10xmax + 5 samsung,..."
                        onChange={handleChangeNote}
                        name="note"
                        value={note}
                    />
                </div>
                {product && 
                    <div className="MM-discription">
                        {product.description && parse(product.description)}
                    </div>
                }
            </div>
        )
    }

    return (
        <div className="ProductModalMobile">
            <React.Fragment key={'bottom'} style={{borderRadius:"8px"}}>
                <Drawer 
                    anchor={'bottom'} 
                    open={open} 
                    onClose={handleClose}
                >
                    <Body 
                        product={product}
                        handleClose={handleClose}
                    />
                </Drawer>
            </React.Fragment>
        </div>
    );
}


export default ProductModalMobile;
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './style.css';

export default class StaticHomeQC extends Component {
  render() {
    return (
      <div className="li-static-home mt-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/* Begin Li's Static Home Image Area */}
              <div className="li-static-home-image" />
              {/* Li's Static Home Image Area End Here */}
              {/* Begin Li's Static Home Content Area */}
              <div className="li-static-home-content">
                <p>Tự lên đơn nhặt hàng<span> ONLINE </span>tại kho buôn ASáng</p>
                <h2>Mẫu mã luôn cập nhật mới nhất</h2>
                <h2>Hàng nghìn sản phẩm luôn có sẵn</h2>
                <p className="schedule">
                  Hóa đơn tối thiếu
                <span> 800K</span>
                </p>
                <div className="default-btn">
                  <Link to="/products" className="links">Đặt hàng ngay </Link>
                </div>
              </div>
              {/* Li's Static Home Content Area End Here */}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

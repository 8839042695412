import React, { Component } from 'react'
import LinkHere from '../components/LinkHere/LinkHere'
import CheckOut from '../components/CheckOut/CheckOut'

export default class CheckOutPage extends Component {
  render() {

    return (
      <div>
        <LinkHere title="Đặt hàng"></LinkHere>
        <CheckOut></CheckOut>
      </div>
    )
  }
}

import React, { Component } from 'react'
import ShoppingCartItems from './ShoppingCartItems'
import CartItemMobile from './CartItemMobile/CartItemMobile';
import { actFetchCartRequest } from '../../redux/actions/cart';
import { connect } from 'react-redux'
import SumTotal from './SumTotal';
import MuiAlert from '@material-ui/lab/Alert';
import callApi from 'utils/apiCaller';

class ShoppingCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minPrice: 0
    }
  }

  async componentDidMount() {
    const token = localStorage.getItem('_auth');
    this.props.fetch_items();
    const res = await callApi(`setting`, 'GET', null, token);
    this.setState({
      minPrice: res.data.minPrice
    })
  }

  showItem(items) {
    let result = null;
    if (items.length > 0) {
      result = items.map((item, index) => {
        return (
          <ShoppingCartItems key={index} item={item} ></ShoppingCartItems>
        );
      });
    }
    return result;
  }

  showItemMobile(items) {
    let result = null;
    if (items && items.length > 0 ) {
      result = items.map((item, index) => {
        return (
          <CartItemMobile key={index} item={item} />
        );
      });
    }
    return result;
  }

  render() {
    const { items } = this.props;

    return (
      <div className="Shopping-cart-area">
        <div className="container">
          {
            items && items.length
            ? <div className="row">
              <div className="col-sm-8 col-xs-12 desktop">
                <form>
                  <div className="table-content table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="li-product-remove">Xóa</th>
                          {/* <th className="li-product-thumbnail">Hình ảnh</th> */}
                          <th className="cart-product-name">Sản phẩm</th>
                          <th className="li-product-price">Giá</th>
                          <th className="li-product-price">Ghi chú</th>
                          <th className="li-product-quantity">Số lượng</th>
                          <th className="li-product-subtotal">Tổng tiền</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.showItem(items)
                        }
                      </tbody>
                    </table>
                  </div>
                </form>
              </div>
              <div className="cart-mobile mobile-flex">
                <h3>Danh sách giỏ hàng</h3>
                  {
                    this.showItemMobile(items)
                  }
              </div>
              <div className="col-sm-4 col-xs-12 sumTotal">
                  <SumTotal minPrice = {this.state.minPrice}></SumTotal>
              </div>
            </div> 
            : <MuiAlert style={{marginTop:"10px"}} severity="info">Chưa có sản phẩm nào trong giỏ hàng</MuiAlert>
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.cart
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetch_items: () => {
      dispatch(actFetchCartRequest())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart)

import React, { Component } from 'react'
import { formatNumber } from '../../config/TYPE'
import { actRemoveCartRequest, actUpdateCartRequest } from '../../redux/actions/cart';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import'./style.css';
toast.configure()

class ShoppingCartItems extends Component {

  upItem = (item) => {
    let newItem = item;
    newItem.quantity++;
    this.props.changQuantityItem(newItem);
  }
  downItem = (item) => {
    if (item.quantity <= 1) {
      return
    }
    let newItem = item;
    newItem.quantity--;
    this.props.changQuantityItem(newItem);
  }

  removeItem = (item) => {
    this.props.removeItem(item);
    toast.success('Xóa sản phẩm thành công')
  }

  handleChange = (item) => {
    return (e) => {
      let newItem = item;
      newItem.note = e.target.value;
      this.props.changQuantityItem(newItem);
    }
  }

  render() {
    const { item } = this.props;
    return (
      <tr>
        <td className="li-product-remove"><Link to="#"><i style={{fontSize: 20}} onClick={() => this.removeItem(item)} className="fa fa-trash" /></Link></td>
        {/* <td className="li-product-thumbnail justify-content-center"><a href="/">
          <div className="fix-cart"> <img className="fix-img" src={item.image ? `${config.api.url_BE}/${item.image}`  : null} alt="Li's Product" /></div>
        </a></td> */}
        <td className="li-product-name">
          <Link className="text-dark" to={`/products/${item.id}`}>{item.nameProduct}</Link>
          {/* Edit */}
          {
            item.categories &&
            <div className="cart-categories">
              {
                item.categories.map((category, index) => {
                  if(index === item.categories.length - 1) {
                    return <Link to={`/categories/${category.categoryId || ''}`} key={index} className="cart-category">{category.category ? category.category.nameCategory : ''}</Link>
                  }
                  return <Link to={`/categories/${category.categoryId || ''}`} key={index} className="cart-category">{category.category ? `${category.category.nameCategory}, ` : ''}</Link>
                })
              }
            </div>
          }
          {/* End */}
        </td>
        <td className="product-subtotal">
          <span className="amount">
          {
            item.discountPrice 
            ? <div className="box-price">
              <span className="price-lineThought">{formatNumber.format(item.price)}</span>
              <span>{formatNumber.format(item.discountPrice)}</span>
            </div>
            :<span>{formatNumber.format(item.price)}</span>
          }
          </span>
        </td>
        <td className="Cart-Item-Note">
          <textarea
            onChange={this.handleChange(item)}
            name="note"
            defaultValue={item.note}
            // value={note}
          />
          {/* <div className="cart-plus-minus">
            <input onChange={() => { }} className="cart-plus-minus-box" value={this.props.item.quantity || 0} />
            <div onClick={() => this.downItem(item)} className="dec qtybutton"><i className="fa fa-angle-down" />
            </div>
            <div onClick={() => this.upItem(item)} className="inc qtybutton"><i className="fa fa-angle-up" /></div>
          </div> */}
        </td>
        <td className="quantity">
          <div className="cart-plus-minus">
            <input onChange={() => { }} className="cart-plus-minus-box" value={this.props.item.quantity || 0} />
            <div onClick={() => this.downItem(item)} className="dec qtybutton"><i className="fa fa-angle-down" />
            </div>
            <div onClick={() => this.upItem(item)} className="inc qtybutton"><i className="fa fa-angle-up" /></div>
          </div>
        </td>
        <td className="product-subtotal">
          <span className="amount">
            {
              item.discountPrice 
              ? formatNumber.format(item.discountPrice * item.quantity)
              : formatNumber.format(item.price * item.quantity)
            }
          </span>
        </td>
      </tr>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    removeItem: (item) => {
      dispatch(actRemoveCartRequest(item))
    },
    changQuantityItem: (item) => {
      dispatch(actUpdateCartRequest(item))
    }
  }
}

export default connect(null, mapDispatchToProps)(ShoppingCartItems)

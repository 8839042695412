export const config = {
  api: {
    url: 'https://phukienasang.com/api',
    url_BE: 'https://phukienasang.com'

  },
  paypal: {
    secretKey: null
  }
  ,
  firebaseConfig: {
    apiKey: "AIzaSyCxBmr5zeTab6aWFwViRPJFfpPnqGbK6NI",
    authDomain: "website-phukienasang.firebaseapp.com",
    databaseURL: "https://website-phukienasang-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "website-phukienasang",
    storageBucket: "website-phukienasang.appspot.com",
    messagingSenderId: "783612925397",
    appId: "1:783612925397:web:fd238d95983311e2de595c"
  }
}

import React, { Component } from 'react'
import LinkHere from '../components/LinkHere/LinkHere'
import ShopCategory from '../components/ShopCategory/ShopCategory'

export default class ShopCategoryPage extends Component {
  render() {
    const {id} = this.props.match.match.params
    return (
      <div>
        <LinkHere title="Danh mục sản phẩm"></LinkHere>
        <ShopCategory id={id}></ShopCategory>
      </div>
    )
  }
}

import React, { Component } from 'react'
import { formatNumber } from '../../config/TYPE'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

class SumTotal extends Component {

  render() {
    const {orderDetail} = this.props;

    let sumPrice = 0;
    let sumDiscount = 0;
  
    if (orderDetail.length > 0) {
      sumPrice = orderDetail.reduce((sum, item) => {
        return sum += item.quantity * item.product.price
      }, 0)

      sumDiscount = orderDetail.reduce((sum, item) => {
        if(item.product.discountPrice) {
          return sum += item.quantity * (item.product.price - item.product.discountPrice)
        }
        return sum
      }, 0)
    }
    
    return (
      <div>
        <div className="cart-page-total">
          <h2 style={{textAlign:"center"}}>Tổng đơn hàng</h2>
          <ul>
            <li>Tổng giá <span>{sumPrice ? formatNumber.format(sumPrice) : 0}</span></li>
            <li>Khuyễn mãi <span>- {formatNumber.format(sumDiscount)}</span></li>
            <li style={{ color: '#ea2c2c' }}>Tổng <span>{formatNumber.format(sumPrice - sumDiscount)}</span></li>
          </ul>
        </div>
      </div>
    )
  }
}

export default SumTotal

import React,{useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {config} from 'config';
// import BeautyStars from 'beauty-stars';
import 'react-toastify/dist/ReactToastify.css';
import ProductModalMobile from '../../common/ProductModelMobile/ProductModelMobile';
import NoticeDiscount from '../../common/NoticeDiscount/NoticeDiscount';
import { useDispatch } from 'react-redux';
import { actGetProductRequest } from '../../../redux/actions/products'
import { actFetchRatingsRequest} from '../../../redux/actions/rating'
import './ProductItemMobile.css';
import WatermarkImage from 'assets/images/frontbgr.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function C_productItemMobile(props) {
    const {product, category} = props;
    const dispath = useDispatch();
    const [openMobile, setOpenMobile] = useState(false);

    let sumRating = 0;
    let count = 0;
    if (product.ratings && product.ratings.length > 0) {
      let totalRating = 0;
      product.ratings.map((item) => {
        count++ ;
        totalRating = totalRating + item.point
        return 0;
      })
      sumRating = Math.round(totalRating / count);
    }

    const handleOpen = () => {
        setOpenMobile(true);
    };
    
    const handleClose = () => {
        setOpenMobile(false)
    };

    const getProductDetail = (id) => {
        return () => {
            const actGetDetail = actGetProductRequest(id)
            const actReloadRating = actFetchRatingsRequest(id);
            dispath(actGetDetail);
            dispath(actReloadRating);
        }
    }

    const convertPrice = (price) => {
        let replacePrice = '';
        if(String(price).endsWith("000")){
          replacePrice = String(price).slice(0, -3).concat("K")
        }else{
            replacePrice = String(price).slice(0, -3).concat(`.${String(price).slice(-3, -2)}K`)
        }
        return replacePrice;
    }

    return (
        <div className="ProductItemMobile PIM-background">
            <div className="image-product-mobile-fixed">
                <Link 
                    to={`/products/${product.id}`}
                    className="CPM-img"
                    onClick = {getProductDetail(product.id)}
                >
                    <LazyLoadImage
                        effect="blur"
                        src={`${config.api.url_BE}/${product.image}`} 
                        alt={product.nameProduct}
                        className="image-mobile"
                    />
                </Link>
                <div className="watermark-price-mobile-container">
                    <div className="background-watermark-mobile">
                        <img src={WatermarkImage} alt="img"/>
                        <div className="watermark-price">{ product.discountPrice ? convertPrice(product.discountPrice) : convertPrice(product.price)}</div>
                    </div>
                </div>
            </div>
            <div className="CPM-body">
                    <Link 
                        to={`/products/${product.id}`} 
                        className = "Product-name-mobile text-truncate"
                        onClick = {getProductDetail(product.id)}
                    >
                        {product.nameProduct}
                    </Link>
                    <Link 
                        to={`/categories/${category ? category.id : ''}`} 
                        className = "name-category text-truncate"
                    >
                        {
                            category && category.nameCategory 
                            ? category.nameCategory 
                            : null
                        }
                    </Link>
                {
                    product.discountPrice
                    ? (
                        <div className="CPIM-PriceContainer">
                            <span className="CPIM-linethrought text-truncate">{product.price.toLocaleString('vi-Vn', {style: 'currency', currency: 'VND'})}</span>
                            <span className="CPIM-RPrice text-truncate">{product.discountPrice.toLocaleString('vi-VN', {style: 'currency', currency: 'VND'})}</span>
                        </div>
                    )
                    : <div className="CPIM-Realprice">
                        <span className="text-truncate">{product.price.toLocaleString('vi-VN', {style: 'currency', currency: 'VND'})}</span>
                    </div>
                }
                <div className="CPM-btn_buy" onClick={handleOpen}>Đặt hàng</div>
            </div>
            {
                product.discountPrice 
                ? <NoticeDiscount
                    style = {{top:"0", right:"0"}}
                    discount={(100 - 100*parseInt(product.discountPrice)/parseInt(product.price)).toFixed(0)}
                />
                : null
            }
            <ProductModalMobile
                product={product}
                open = {openMobile}
                handleClose = {handleClose}
            />
        </div>
    )
}

C_productItemMobile.propTypes = {
    product: PropTypes.object.isRequired
}

export default C_productItemMobile;


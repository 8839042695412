import React, { Component } from 'react';
import ProductItems from './ProductItems';
import { connect } from 'react-redux';
import { actFetchProductsNewRequest } from '../../../../redux/actions/products';
import Slider from "react-slick";
import './style.css';
import ProductModal from './ProductModal';

class ProductContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0
    }
  }

  componentDidMount() {
    const { offset } = this.state;
    this.props.fetch_products_new(offset);
  }

  render() {
    const { products } = this.props;
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 7,
      slidesToScroll: 5,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 4,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 3,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 760,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 3,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        }
      ]
    };

    return (
      <div className="tab-content mt-1 desktop">
        <div id="li-new-product">
          <div className="new-product-content">
            <Slider {...settings}>
              {products && products.length ? products.map((product, index) => {
                return (
                  <div key={index}><ProductItems product={product} ></ProductItems> </div>
                )
              }) : null
              }
            </Slider>
           </div>
          <ProductModal />
         
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.productsNew
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetch_products_new: (offset) => {
      dispatch(actFetchProductsNewRequest(offset))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductContent);
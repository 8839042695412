import React, { Component } from 'react'
import SliderLeft from './SliderLeft'
import BannerRight from './BannerRight'
import callApi from 'utils/apiCaller';
import {config} from 'config'

export default class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
    banners: []
    };
  }

  async componentDidMount() {
    const res = await callApi(`banner`, 'GET', null, null);
    console.log('haha', res.data)
    this.setState({
        banners: res.data
    })
  }

  render() {
    return (
      <div className="slider-with-banner">
        <div className="container ">
          <div className="row slider-container">
            {/* Begin Slider Area */}
            {/* <SliderLeft banners = {this.state.banners.slice(0,4)}></SliderLeft>*/}
            <SliderLeft banners  =  {this.state.banners.filter(banner => banner.type === 'primary')}></SliderLeft>
            {/* Slider Area End Here */}
            {/* Begin Li Banner Area */}
            <BannerRight banners = {this.state.banners.filter(banner => banner.type === 'subSlide')}></BannerRight>
            {/* Li Banner Area End Here */}
          </div>
        </div>
      </div>
    )
  }
}

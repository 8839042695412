import React, { Component } from 'react'
import callApi from '../../utils/apiCaller';
import { uploadImage } from '../../utils/upload'
import './style.css'
import { actUpdateMeRequset, actChangePasswordMeRequset } from '../../redux/actions/auth';
import { connect } from 'react-redux'
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';
import { startLoading, doneLoading } from '../../utils/loading'
import {config} from 'config';

toast.configure()

let token;
const override = css`
    display: block;
    margin: 0 auto;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
`;
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      phone: '',
      email: '',
      address: '',
      avatar: null,
      loading: false,
      img: null,
      oldPassword: '',
      password: '',
      rePassword: ''
    }
  }

  async componentDidMount() {
    token = localStorage.getItem('_auth');
    const res = await callApi('users/me', 'GET', null, token);
    this.setState({
      name: res.data.results.name,
      email: res.data.results.email,
      phone: res.data.results.phone,
      address: res.data.results.address,
      avatar: res.data.results.avatar,
    })
  }

  handleChangeImage = (event) => {
    if (event.target.files[0]) {
      const img = event.target.files[0];
      this.setState(() => ({ img }));
    }
    const output = document.getElementById('output');
    output.src = URL.createObjectURL(event.target.files[0]);
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleSubmitChangePassword = (event) => {
    event.preventDefault();
    const { password, oldPassword, rePassword } = this.state;
    if (password.length < 6 || oldPassword.length < 6) {
      return toast.error('Mật khẩu phải lớn hơn 6 ký tự');
    }
    if (password !== rePassword) {
      toast.error('Nhập lại mật khẩu không đúng');
      return
    }
    const data = {
      oldPassword,
      newPassword: password
    }
    startLoading();
    this.props.change_pw_me(data, token);
    doneLoading();
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { name, address, phone } = this.state;
    let { img, avatar } = this.state;
    this.setState({
      loading: true
    })
    //upload image to firebase
    if (img !== null && img !== avatar) {
      avatar = await uploadImage(img);
    }
    const newAvatar = (avatar === '') ? null : avatar
    const newAddress = (address === '') ? null : address
    const newPhone = (phone === '') ? null : phone
    const newName = (name === '') ? null : name

    //edit
    const editUser = {
      name: newName,
      address: newAddress,
      avatar: newAvatar,
      phone: newPhone,
    }
    await this.props.update_me(editUser, token);
    this.setState({
      loading: false,
    })
  }

  render() {
    const { name, email, phone, address, avatar, loading, oldPassword, password, rePassword } = this.state;
    return (
      <div className="container emp-profile">
        <div className='sweet-loading'>
          <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={30}
            color={'#796aeebd'}
            loading={loading}
          />
        </div>
        <form method="post" className="Profile-body">
          <div className="container-img">
            <div className="profile-img">
              <img id="output" src={avatar ? `${config.api.url_BE}/${avatar}` : 'https://i.ibb.co/NCdx7FF/avatar-Default.png'} alt="not found" />
            </div>
            <span className="btn btn-default btn-file" style={{ color: '#212529' }}>
              Chọn ảnh <input onChange={this.handleChangeImage} type="file" accept="image/png, image/gif, image/jpeg"/>
            </span>
          </div>
          <div className="row container-profile">
            <div className="profile-head">
              <p className="proile-rating"></p>
              <ul className="nav nav-tabs" style={{ paddingTop: 40 }} id="myTab" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Thông tin</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Đổi mật khẩu</a>
                </li>
              </ul>
            </div>
            <div className="container-form-profile">
              <div className="tab-content profile-tab" id="myTabContent">
                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  <form onSubmit={(event) => this.handleSubmit(event)} >
                    <div className="input-profile">
                      <div>
                        <label>Tên</label>
                      </div>
                      <div>
                        <input name="name" onChange={this.handleChange} value={name} className="form-control form-control-sm" type="text" />
                      </div>
                    </div>
                    <div className="input-profile">
                      <div>
                        <label>Email</label>
                      </div>
                      <div>
                        <input disabled value={email} className="form-control form-control-sm" type="text" />
                      </div>
                    </div>
                    <div className="input-profile">
                      <div>
                        <label>Địa chỉ</label>
                      </div>
                      <div>
                        <input onChange={this.handleChange} name="address" value={address} className="form-control form-control-sm" type="text" />
                      </div>
                    </div>
                    <div className="input-profile">
                      <div>
                        <label>Số điện thoại</label>
                      </div>
                      <div>
                        <input onChange={this.handleChange} type="number" name="phone" value={phone} className="form-control form-control-sm" />
                      </div>
                    </div>
                    <div className="input-profile">
                      <div className="col-md-6">
                      </div>
                      <div className="col-md-3">
                        <button style={{ backgroundColor: '#0b0bed8c', border: '#0b0bed8c' }} type="submit" className="btn btn-primary">Cập nhật</button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                  <form onSubmit={(event) => this.handleSubmitChangePassword(event)} >
                    <div className="row mb-1">
                      <div className="col-md-3">
                        <label>Mật khẩu hiện tại</label>
                      </div>
                      <div className="col-md-9">
                        <input type="password" name="oldPassword" value={oldPassword} onChange={this.handleChange} className="form-control form-control-sm" />
                      </div>
                    </div>
                    <div className="row mb-1">
                      <div className="col-md-3">
                        <label>Mật khẩu mới</label>
                      </div>
                      <div className="col-md-9">
                        <input type="password" name="password" value={password} onChange={this.handleChange} className="form-control form-control-sm" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <label>Nhập lại mật khẩu</label>
                      </div>
                      <div className="col-md-9">
                        <input type="password" name="rePassword" value={rePassword} onChange={this.handleChange} className="form-control form-control-sm" />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-3">
                      </div>
                      <div className="col-md-3">
                        <button style={{ backgroundColor: '#0b0bed8c', border: '#0b0bed8c' }} type="submit" className="btn btn-primary">Cập nhật</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div >
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    update_me: (data, token) => {
      dispatch(actUpdateMeRequset(data, token))
    },
    change_pw_me: (data, token) => {
      dispatch(actChangePasswordMeRequset(data, token))
    }
  }
}

export default connect(null, mapDispatchToProps)(Profile)
import React, { Component } from "react";
import { formatNumber } from "../../config/TYPE";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { actAddCartRequest } from "../../redux/actions/cart";
import { actAddRatingRequest, actFetchRatingsRequest } from "../../redux/actions/rating";
import callApi from "../../utils/apiCaller";
import { actGetProductRequest, actFetchProductsOtherRequest } from "../../redux/actions/products";
import ImgSlider from './ImgSlider/ImgSlider';
import "./style.css";
toast.configure();

let token;

class ProductViewDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: 1,
      ratingPoint: 0,
      modalIsOpen: false,
      textRating: "",
      note: ''
    };
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  async componentDidMount() {
    token = localStorage.getItem("_auth");
    const { id } = this.props;
    this.props.get_product(id);
    this.props.fetch_ratings(id);
    const res = await callApi(`products/${id}`, "GET", null, null);
    if (res && res.status === 200) {
      if(res.data.categories.length > 0) {
        const random = Math.floor(Math.random() * res.data.categories.length);
        this.props.get_product_others(null, res.data.categories[random].categoryId);
      }
    }
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    this.subtitle.style.color = "#2d3136";
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  handleChangeRating = value => {
    this.setState({
      ratingPoint: value
    });
  };

  handleChange = event => {
    let name = event.target.name;
    let value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    this.setState({
      [name]: value
    });
  };

  handleChangeNumber = async (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({
        quantity: e.target.value
      })
    }
  }

  handleSubmitRating = async (event, productId) => {
    event.preventDefault();
    const { ratingPoint, textRating } = this.state;
    if (!ratingPoint) {
      return toast.error("Cho điểm trước khi gửi đánh giá");
    }
    const newTextRating = textRating ? textRating : null;
    const data = {
      point: ratingPoint,
      content: newTextRating
    };
    if (!token) {
      return toast.error("Hãy đăng nhập trước khi đánh giá");
    }
    this.props.add_rating(productId, data, token);
    this.setState({
      modalIsOpen: false,
      ratingPoint: 0
    });
  };

  upItem = () => {
    let quantity = this.state.quantity;
    this.setState({
      quantity: ++quantity
    });
  };

  downItem = () => {
    let quantity = this.state.quantity;
    if (quantity <= 1) {
      return toast.error("Số lượng sản phẩm tối thiếu là 1");
    }
    this.setState({
      quantity: --quantity
    });
  };

  addItemToCart = product => {
    const { quantity, note } = this.state;
    this.props.addCart(product, quantity, note);
    this.setState({
      quantity: 1,
      note: ''
    })
  };
  
  render() {
    const { quantity, ratingPoint, note } = this.state;
    const { product, productRatings } = this.props;
    let sumRating = 0;
    let count = productRatings.length ? productRatings.length : 0;
    if (productRatings && productRatings.length > 0) {
      let totalRating = 0;
      productRatings.forEach(item => {
        totalRating = totalRating + item.point;
      });
      sumRating = Math.round(totalRating / count);
    }

    return (
      <div className="content-wraper">
        <div className="container">
          <div className="row single-product-area">
            <div className="col-lg-5 col-md-6 mt-2">
              <div className="product-details-left">
                <div className="product-details-images slider-navigation-1">
                  <div className="lg-image">
                    <div className="fix-width-slick">
                      {
                        product.gallery && product.gallery.length && <ImgSlider images = {product.gallery}/>                        
                      }          
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-6">
              <div className="product-details-view-content sp-normal-content product-view-detail">
                <div id='portal'></div>
                <div className="product-info">
                  <h2>{product.nameProduct}</h2>
                  <div>
                    {product.categories && product.categories.map((category, index) => {
                      return (
                        <Link  
                          to={`/categories/${category ? category.categoryId : ''}`}
                          className="product-details-ref"
                        >
                          {
                            category.category.nameCategory
                          }, <span> </span>
                        </Link>
                      )
                    })}
                  </div>
                  <div className="price-box pt-20">
                    {
                      product.discountPrice 
                      ? <div>
                        <span className="new-price new-price-2">{formatNumber.format(product.discountPrice)}</span>
                        <span className="old-price price-linethrought">{formatNumber.format((product.price))}</span>
                      </div>
                      : <span className="new-price new-price-2">{formatNumber.format(product.price)}</span>
                    }
                  </div>
                  <div className="product-desc">
                    <p>
                    <span dangerouslySetInnerHTML={{__html: product.description}}></span>
                    </p>
                  </div>
                  <div className="single-add-to-cart">
                    <form className="cart-quantity">
                      <div>
                        <label>Ghi chú sản phẩm (Tùy chọn)</label>
                        <textarea
                          placeholder="Ghi rõ số lượng sản phẩm nếu cùng chung số lượng sản phẩm, vd: 10xmax + 5 samsung,..."
                          onChange={this.handleChange}
                          name="note"
                          value={note}
                        />
                      </div>
                      <div className="totalPrice-text">
                        {
                          product.discountPrice
                          ? <span>Tổng tiền: { formatNumber.format(product.discountPrice*quantity) }</span>
                          : <span>Tổng tiền: { formatNumber.format(product.price*quantity) }</span> 
                        }
                      </div>
                      <div className="quantity">
                        <label>Số lượng</label>
                        <div className="cart-plus-minus">
                          <input
                             onChange={this.handleChangeNumber}
                            className="cart-plus-minus-box"
                            value={quantity}
                            type="text"
                          />
                          <div onClick={() => this.downItem()} className="dec qtybutton">
                            <i className="fa fa-angle-down" />
                          </div>
                          <div onClick={() => this.upItem()} className="inc qtybutton">
                            <i className="fa fa-angle-up" />
                          </div>
                        </div>
                      </div>
                      <div style={{ paddingTop: 13 }}>
                        <Link
                          onClick={() => this.addItemToCart(product)}
                          to="#"
                          className="add-to-cart"
                        >
                          Thêm vào giỏ hàng
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    product: state.product,
    productRatings: state.productRatings
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addCart: (item, quantity, note) => {
      dispatch(actAddCartRequest(item, quantity, note));
    },
    add_rating: (productId, rating, token) => {
      dispatch(actAddRatingRequest(productId, rating, token));
    },
    get_product: productId => {
      dispatch(actGetProductRequest(productId));
    },
    get_product_others: (q, categoryId) => {
      dispatch(actFetchProductsOtherRequest(q, categoryId));
    },
    fetch_ratings: productId => {
      dispatch(actFetchRatingsRequest(productId));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductViewDetail);

import React, {useState} from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { actRemoveCartRequest, actUpdateCartRequest } from '../../../redux/actions/cart';
import { useDispatch } from 'react-redux';
import {
    AiOutlinePlus,
    AiOutlineMinus
} from "react-icons/ai";
import { formatNumber } from '../../../config/TYPE'

import './CartItemMobile.css';

const CartItemMobile = (props) => {
    const {item} = props;
    const [disable, setDisable] = useState(true);
    const dispath = useDispatch();

    const upItem = (item) => {
        let newItem = item;
        newItem.quantity++;
        const action = actUpdateCartRequest(newItem);
        dispath(action);
    }

    const downItem = (item) => {
        if (item.quantity <= 1) {
            return
        }
        let newItem = item;
        newItem.quantity--;
        const action = actUpdateCartRequest(newItem);
        dispath(action);
    }

    const handleChangeAmount = (item) => {
        return (e) => {
            const re = /^[0-9\b]+$/;
            if (e.target.value === '' || re.test(e.target.value)) {
                let newItem = item;
                newItem.quantity = e.target.value;
                const action = actUpdateCartRequest(newItem);
                dispath(action);
            }
        }
    }

    const handleChangeNote = (e) => {
        let newItem = item;
        newItem.note = e.target.value;
        const action = actUpdateCartRequest(newItem);
        dispath(action);
    }

    const onClickBtnEdit = () => {
        setDisable(false);
    }

    const removeItem = () => {
        const action = actRemoveCartRequest(item);
        dispath(action);
        toast.success('Xóa sản phẩm thành công')
    }

    return (
        <div className="CartItemMobile">
            {/* <Link to="#" className="cart-item-mobile-delete"><i style={{fontSize: "14px"}} onClick={() => this.removeItem(item)} className="fa fa-trash" /></Link> */}
            <div className="cart-item-mobile-body">
                <div className="conatiner-name-totalPrice">
                    <Link to={`/products/${item.id}`} className="cart-item-mobile-name">{item.nameProduct}</Link>
                    <span className="totalPrice-moile">
                        {
                        item.discountPrice 
                        ? formatNumber.format(item.discountPrice * item.quantity)
                        : formatNumber.format(item.price * item.quantity)
                        }
                    </span>
                </div>
                {
                    item.categoryId_categories &&
                    <div className="cart-categories">
                    {
                        item.categoryId_categories.map((category, index) => {
                        if(index === item.categoryId_categories.length - 1) {
                            return <Link to={`/categories/${category.id || ''}`} key={index} className="cart-item-mobile-categoryName">{ category.nameCategory || ''}</Link>
                        }
                        return <Link to={`/categories/${category.id || ''}`} key={index} className="cart-item-mobile-categoryName">{`${category.nameCategory}, ` || ''}</Link>
                        })
                    }
                    </div>
                }
                {
                    item.discountPrice 
                    ? <div>
                        <span className="cart-item-price">{formatNumber.format(item.discountPrice)}</span>
                        <span className="linethrought">{formatNumber.format(item.price)}</span>
                    </div>
                    :<span className="cart-item-price">{formatNumber.format(item.price)}</span>
                }
                <div className="container-note">
                    <p>Ghi chú sản phẩm (Tùy chọn) <span onClick={onClickBtnEdit}>Sửa</span></p>
                    {/* <p>{item.note}</p> */}
                    <textarea onChange={handleChangeNote} className="cart-plus-minus-box" value={item.note} disabled={disable}/>
                </div>
                <div className="conatiner-cart-amount">
                    <div className="cart-amount">
                        <div onClick={() => downItem(item)} className="downItem handleItem"><AiOutlineMinus /></div>
                        <input onChange={handleChangeAmount(item)} className="cart-plus-minus-box" value={item.quantity} type="number" min="0"/>
                        <div onClick={() => upItem(item)} className="upItem handleItem"><AiOutlinePlus /></div>
                    </div>
                    <p onClick={removeItem}>Xóa</p>
                </div>
            </div>
        </div>
    );
};


CartItemMobile.propTypes = {

};


export default CartItemMobile;

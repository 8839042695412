import React, { Component } from 'react'
import callApi from '../../utils/apiCaller';
import { Redirect } from 'react-router-dom';
import Moment from 'react-moment';
import { formatNumber } from '../../config/TYPE'
import { Link } from 'react-router-dom'
import './style.css';
import 'react-toastify/dist/ReactToastify.css';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { PullToRefresh} from "react-js-pull-to-refresh";
import { connect } from 'react-redux';

class HistoryBooking extends Component {

  constructor(props) {
    super(props);
    this.state = {
      historyBooking: [],
      redirectToLogin: false
    }
  }

  async componentDidMount() {
    const {token} = this.props;
    if(!token) {
      return this.setState({redirectToLogin: true})
    }

    const res = await callApi('users/me/historyBooking', 'GET', null, token);
    
    this.setState({
      historyBooking: res.data.results
    })
  }

  async componentDidUpdate (prevProps, prevState) {
    const {token} = this.props;
    if ( prevProps.token !== token) {
      if(!token) {
        return this.setState({redirectToLogin: true})
      }
      const res = await callApi('users/me/historyBooking', 'GET', null, token);
      this.setState({
        historyBooking: res.data.results
      })
    }
  }

  showOrder(status){
    if (status === 'Unconfirm')  
      return <div><label className="fix-status" style={{background: '#ff9800'}} >Đang duyệt...</label></div>
    if (status === 'Confirm') 
      return <div><label className="fix-status" style={{background: '#337ab7'}} >Đã duyệt</label></div>
    if (status === 'Shipping') 
      return <div><label className="fix-status" style={{background: '#634a41'}} >Đang giao hàng</label></div>
    if (status === 'Complete') 
      return <div><label className="fix-status" style={{background: '#5cb85c'}} >Đã hoàn thành</label></div>
    if (status === 'Canceled') 
      return <div><label className="fix-status" style={{background: '#d9534f'}} >Đã hủy</label></div>
  }

  async onRefresh () {
    const {token} = this.props;
    const res = await callApi('users/me/historyBooking', 'GET', null, token);
    this.setState({
      historyBooking: res.data.results
    })
    return new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });
  }

  render() {
    const { historyBooking, redirectToLogin } = this.state;

    if (redirectToLogin) {
      return <Redirect to="/login"></Redirect>
    }

    return (
      <div className="booking-area">
        <div className="container desktop">
          <h3 className="title-history-booking" onClick={this.onRefresh.bind(this)} data-toggle="tooltip" title="Trạng thái" aria-hidden="true">
            Lịch sử đơn hàng 
          </h3>
          { 
            historyBooking && historyBooking.length 
            ? <div className="row">
              <div className="col-sm-12 col-xs-12">
                <form>
                  <div className="table-content table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="li-product-remove">Mã đơn hàng</th>
                          <th className="li-product-thumbnail">Ngày đặt</th>
                          <th className="cart-product-name">Trạng thái</th>
                          <th className="li-product-price">Tổng đơn</th>
                          <th className="li-product-quantity">Ghi chú</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          historyBooking && historyBooking.length ? historyBooking.map((item, index) => {
                            return (
                              <tr>
                                <th className="li-product-remove"><Link to={`/orders/history/item/${item.id}`}>#{item.id}</Link></th>
                                <th className="li-product-thumbnail"><Moment format="DD/MM/YYYY">{item.createAt}</Moment></th>
                                <th className="cart-product-status">{this.showOrder(item.status)}</th>
                                <th className="li-product-price">
                                  {
                                    item.totalDiscountPrice 
                                    ? <div>
                                      <p className="price-linethought">{formatNumber.format(item.totalPrice)}</p>
                                      <p>{formatNumber.format(item.totalPrice - item.totalDiscountPrice)}</p>
                                    </div>
                                    : <p>{formatNumber.format(item.totalPrice - item.totalDiscountPrice)}</p>
                                  }
                                </th>
                                {
                                  item.note 
                                  ? <th className="li-product-quantity">{item.note}</th>
                                  : <th className="li-product-quantity"></th>
                                }
                              </tr>
                            )
                          }) : null  
                        }   
                      </tbody>
                    </table>
                  </div>
                </form>
              </div>
            </div> 
            : <MuiAlert style={{marginTop:"10px"}} severity="info">Chưa có đơn hàng</MuiAlert>
          }
        </div>
        <div className="mobile-flex history-orders-mobile">
          <PullToRefresh
            pullDownContent={<div className="process-reload"><CircularProgress style={{height:"22px", width:"22px"}}/></div>}
            releaseContent={<div className="process-reload"><CircularProgress style={{height:"22px", width:"22px"}}/></div>}
            refreshContent={<div className="process-reload"><CircularProgress style={{height:"22px", width:"22px"}}/></div>}
            pullDownThreshold={40}
            onRefresh={this.onRefresh.bind(this)}
            triggerHeight={400}
            backgroundColor='white'
            startInvisible={true}
          >
            <h3 className="title-history-booking">Lịch sử đơn hàng</h3>
            {
              historyBooking && historyBooking.length 
              ? <div>
                {
                  historyBooking.map((item, index) => (
                    <div className="history-mobile-item">
                      <div><span>Mã đơn hàng: </span><Link className="id-order-history" to={`/orders/history/item/${item.id}`}> #{item.id}</Link></div>
                      <div>Ngày đặt: <Moment format="DD/MM/YYYY">{item.createAt}</Moment></div>
                      <div className="order-status-mobile"><span>Trạng thái:</span> {this.showOrder(item.status)}</div>
                      <div className="history-item-total">Tổng đơn: 
                        {
                          item.totalDiscountPrice 
                          ? <div className="history-price-container">
                            <p className="price-history">{formatNumber.format(item.totalPrice - item.totalDiscountPrice)}</p>
                            <p className="price-history-linethrought">{formatNumber.format(item.totalPrice)}</p>
                          </div>
                          : <p className="price-history">{formatNumber.format(item.totalPrice - item.totalDiscountPrice)}</p>
                        }
                      </div>
                      <div>
                        {
                          item.note && <p className="history-note">Ghi chú: {item.note}</p>
                        }
                      </div>
                    </div>
                  ))
                }
              </div>
              : <MuiAlert style={{marginTop:"10px"}} severity="info">Chưa có đơn hàng</MuiAlert>
            }
          </PullToRefresh>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryBooking);

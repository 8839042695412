import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ZaloImg from 'assets/images/ZaloImg.png';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import EventNoteIcon from '@material-ui/icons/EventNote';
import './style.css'

export default class Social extends Component {
  render() {
    return (
      <div className="fix-div-social">
        <ul className="fix-social social-link">
        
        <li className="twitter">
          <a href="https://zalo.me/0902122202" data-toggle="tooltip" title="Bán buôn">
            <img className="zalo-img" src={ZaloImg} alt="zalo"/>
          </a>
        </li>

           
        <li className="twitter">
          <a href="https://zalo.me/0931733772" data-toggle="tooltip" title="Bán buôn">
            <img className="zalo-img" src={ZaloImg} alt="zalo"/>
          </a>
        </li>

        <li className="twitter">
          <a href="https://zalo.me/0985674335 " data-toggle="tooltip" title="Bán buôn">
            <img className="zalo-img" src={ZaloImg} alt="zalo"/>
          </a>
        </li>

        <li className="twitter">
          <a href="https://zalo.me/0961678033 " data-toggle="tooltip" title="Bán buôn">
            <img className="zalo-img" src={ZaloImg} alt="zalo"/>
          </a>
        </li>
        
        <li className="facebook">
          <a href="https://facebook.com/phukienasang" data-toggle="tooltip" title="FanPage">
            <i className="fa fa-facebook" />
          </a>
        </li>
        <li className="youtube">
          <a href="tel:0931733772" data-toggle="tooltip" title="Điện thoại">
            <LocalPhoneIcon style={{color:"#fff"}}/>
          </a>
        </li>
        <li className="google-plus">
          <Link to="/contact" data-toggle="tooltip" title="Liên hệ">
            <EventNoteIcon style={{color:"#fff"}}/>
          </Link>
        </li>
      </ul>
      </div>
      
    )
  }
}

import React from 'react';
//import CircularProgress from '@material-ui/core/CircularProgress';
import './Spinner.css';

const Spinner = (props) => {
    const { className } = props;

    return (
        <div className={`Spinner ${className}`}>
           {/**<CircularProgress /> */} 
        </div>
    );
}

export default Spinner;

import React, { Component } from 'react'
import { formatNumber } from '../../config/TYPE'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import { actGetProductRequest, actFetchProductsOtherRequest } from '../../redux/actions/products';
import { actAddCartRequest } from '../../redux/actions/cart';
import { actFetchRatingsRequest} from '../../redux/actions/rating';
import { startLoading, doneLoading } from '../../utils/loading'
import { connect } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css';
// import BeautyStars from 'beauty-stars';
import './style.css'
import parse from 'html-react-parser'
import {config} from 'config';
import WatermarkImage from 'assets/images/frontbgr.png';
toast.configure()

class ProductItem extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      quantity: 1,
      note: ''
    }
    this.handleClose = React.createRef(null);
  }

  upItem = (quantity) => {
    this.setState({
      quantity: quantity + 1
    })
  }
  downItem = (quantity) => {
    if (quantity <= 1) {
      return
    }
    this.setState({
      quantity: quantity - 1
    })
  }
  handleChange = event => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({
      [name]: value
    });
  }

  handleChangeNumber = async (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({
        quantity: e.target.value
      })
    }
  }

  getInfoProduct = (id) => {
    this.setState({
      quantity: 1,
      note: ''
    })
    this.props.getInfoProduct(id);
  }

  addItemToCart = (product) => {
    startLoading()
    this.props.addCart(product);
    doneLoading();
  }

  // vi su dung form nen tach ra co event de ko bi load lai page
  addItemToCart2 = (event, product) => {
    const { quantity, note } = this.state;
    event.preventDefault();
    startLoading()
    this.props.addCart(product, quantity, note);
    doneLoading();
    this.handleClose.current.click();
    this.setState({
      quantity: 1,
      note: ''
    })
  }

  getToProductDetail = (id, cateogryId) => {
    const { offset } = this.state;
    startLoading()
    this.props.getProductDetail(id);
    this.props.fetch_products_other(offset, cateogryId);
    this.props.fetch_product_ratings(id);
    doneLoading();
  }
  
  convertPrice = (price) => {
    let replacePrice = '';
    if(String(price).endsWith("000")){
      replacePrice = String(price).slice(0, -3).concat("K")
    }else{
        replacePrice = String(price).slice(0, -3).concat(`.${String(price).slice(-3, -2)}K`)
    }
    return replacePrice;
  }

  render() {
    const { product, getProduct } = this.props;
    const { quantity, note } = this.state;
    let sumRating = 0;
    let count = 0;
    if (product.ratings && product.ratings.length > 0) {
      let totalRating = 0;
      product.ratings.map((item) => {
        count++ ;
        totalRating = totalRating + item.point;
        return 0;
      })
      sumRating = Math.round(totalRating / count);
    }
    return (
      <div className="col-lg-2 col-md-3 col-sm-6 mt-40">
        <div className="single-product-wrap ProductItem">
          <div className="fix-img-div-new product-image">
            <Link to={`/products/${product.id}`} >
              <img className="fix-img-new" src={`${config.api.url_BE}/${product.image ? product.image : null}`} alt={product.nameProduct} />
            </Link>
            {
              product.discountPrice ? <span className="sticker">{
                `-${(100 - 100*parseInt(product.discountPrice)/parseInt(product.price)).toFixed(0)}%`
              }</span> : null
            }
            <div className="watermark-price-container">
              <div className="background-watermark">
                <img src={WatermarkImage} alt="img"/>
                <div className="watermark-price">{ product.discountPrice ? this.convertPrice(product.discountPrice) : this.convertPrice(product.price)}</div>
              </div>
            </div>
          </div>
          <div className="product_desc">
            <div className="product_desc_info">
              <div className="product-review text-truncate">
                <h5 className="manufacturer">
                  <Link to={`/categories/${product.categoryId_categories && product.categoryId_categories.length > 0 ? product.categoryId_categories[0].id : ''}`} className="category-name text-truncate">
                    {
                      product.categoryId_categories && product.categoryId_categories.length > 0 ? product.categoryId_categories[0].nameCategory : ''
                    }
                  </Link>
                </h5>
              </div>
              <h4><Link className="product_name text-truncate" to={`/products/${product.id}`}>{product.nameProduct}</Link></h4>
              <div className="price-box">
                {
                  product.discountPrice 
                  ? <div>
                    <span className="new-price new-price-2">{formatNumber.format(product.discountPrice)}</span>
                    <span className="old-price">{formatNumber.format((product.price))}</span>
                  </div>
                  : <span className="new-price new-price-2">{formatNumber.format(product.price)}</span>
                }
              </div>
            </div>
            <div className="add-actions">
              <ul className="add-actions-link">
                <li className="add-cart active" data-toggle="modal" data-target="#exampleModalCenter"><Link to="#" onClick={() => this.getInfoProduct(product.id)} >Đặt hàng</Link></li>
              </ul>
            </div>
          </div>
        </div>
        {/*// QUICK VIEW */}
        <div className="modal fade modal-wrapper" id="exampleModalCenter">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <button ref={this.handleClose} type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
                <div className="modal-inner-area row">
                  <div className="col-lg-5 col-md-6 col-sm-6">
                    {/* Product Details Left */}
                    <div className="product-details-left">
                      <div className="product-details-images slider-navigation-1">
                        <div className="lg-image">
                          <img src={`${config.api.url_BE}/${getProduct.image}`} alt={product.nameProduct} />
                        </div>
                      </div>
                    </div>
                    {/*// Product Details Left */}
                  </div>
                  <div className="col-lg-7 col-md-6 col-sm-6">
                    <div className="product-details-view-content">
                      <div className="product-info">
                        <h2>{getProduct.nameProduct}</h2>
                        <div className="rating-box">
                        </div>
                        <div className="price-box pt-20">
                          {
                            getProduct.discountPrice 
                            ? <div>
                              <span className="new-price new-price-2">{formatNumber.format(getProduct.discountPrice)}</span>
                              <span className="old-price">{formatNumber.format((getProduct.price))}</span>
                            </div>
                            : <span className="new-price new-price-2">{formatNumber.format(getProduct.price)}</span>
                          }
                        </div>
                        <div className="product-desc">
                          <p>
                            <span>{getProduct.description && parse(getProduct.description)}
                            </span>
                          </p>
                        </div>
                        <div className="product-variants">
                        </div>
                        <div className="single-add-to-cart">
                          <form className="cart-quantity" onSubmit={(event) => this.addItemToCart2(event, getProduct)}>
                            <div>
                              <label>Ghi chú sản phẩm (Tùy chọn)</label>
                              <textarea
                                placeholder="Ghi rõ số lượng sản phẩm nếu cùng chung số lượng sản phẩm, vd: 10xmax + 5 samsung,..."
                                onChange={this.handleChange}
                                name="note"
                                value={note}
                              />
                            </div>
                            <div className="modal-total-price">
                              {
                                getProduct.discountPrice
                                ? <span>Tổng tiền: { formatNumber.format(getProduct.discountPrice*quantity) }</span>
                                : <span>Tổng tiền: { formatNumber.format(getProduct.price*quantity) }</span> 
                              }
                            </div>
                            <div className="quantity">
                              <label>Số lượng</label>
                              <div className="cart-plus-minus">
                                <input type="text"
                                  className="cart-plus-minus-box"
                                  name={quantity}
                                  value={quantity}
                                  onChange={this.handleChangeNumber}
                                />
                                <div onClick={() => this.downItem(quantity)} className="dec qtybutton"><i className="fa fa-angle-down" /></div>
                                <div onClick={() => this.upItem(quantity)} className="inc qtybutton"><i className="fa fa-angle-up" /></div>
                              </div>
                            </div>
                            <button className="add-to-cart" type="submit">Đặt hàng</button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* single-product-wrap end */}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    getProduct: state.product
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProductDetail: (id) => {
      dispatch(actGetProductRequest(id))
    },
    addCart: (item, quantity, note) => {
      dispatch(actAddCartRequest(item, quantity, note))
    },
    fetch_products_other: (q, categoryId) => {
      dispatch(actFetchProductsOtherRequest(q, categoryId))
    },
    getInfoProduct: (id) => {
      dispatch(actGetProductRequest(id))
    },
    fetch_product_ratings: (id) => {
      dispatch(actFetchRatingsRequest(id));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductItem)

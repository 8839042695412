import React, { Component } from 'react'

export default class AboutPage extends Component {
  render() {
    return (
      <div>
  <div className="breadcrumb-area">
    <div className="container">
      <div className="breadcrumb-content">
        <ul>
          <li><a href="index.html">Home</a></li>
          <li className="active">/About Us</li>
        </ul>
      </div>
    </div>
  </div>
  {/* Li's Breadcrumb Area End Here */}
  {/* about wrapper start */}
  <div className="about-us-wrapper pt-60 pb-40">
    <div className="container">
      <div className="row">
        {/* About Text Start */}
        <div className="col-lg-6 order-last order-lg-first">
          <div className="about-text-wrap">
            <h2><span>Hãy đồng hành cùng chúng tôi</span>Lợi nhuận của chính bạn</h2>
            <p>Chúng tôi phân phối, bán buôn sản phẩm phụ kiện điện thoại các loại, các sản phẩm hãng BYZ, HOCO, KingKong, REMAX... Cam kết giá buôn tốt nhất cho thợ và các cửa hàng bán.</p>
            <p>Một số khách hàng của chúng tôi nói rằng họ tin tưởng chúng tôi và mua sản phẩm của chúng tôi mà không có bất kỳ sự ẩn nhặt nào vì họ tin tưởng chúng tôi và luôn vui vẻ mua sản phẩm của chúng tôi.</p>
            <p>Chúng tôi luôn cố gắng, cập nhật đưa những sản phẩm phụ kiện điện thoại mới nhất phù hợp với xu thế để cung cấp tới bạn hàng, mặc dù vẫn còn khiêm tốn so với nhu cầu rộng lớn. Tuy nhiên chúng tôi cố gắng đáp ứng tới 98% số lượng mặt hàng như công bố.</p>
            <p>Với phương châm "Chúng ta cùng thành công 💯" , chúng tôi luôn đồng hành, động viên, hỗ trợ các cửa hàng mới mở với chính sách hợp lý. Với bạn hàng lâu năm chúng tôi cũng không có chính sách hỗ trợ việc giam hàng hay hưởng ứng cho việc nhập số lượng lớn mặt hàng khan hiếm. </p>
            <p>Chúng tôi luôn mong muốn những mối quan hệ bạn hàng lâu dài và cùng nhau chia sẻ lợi ích công bằng nhất tới mọi người ở mức hết sức có thể.</p>
          </div>
           <img className="img-full" src="/images/about-us/certificateBYZ.jpg" alt="CertificateBYZ" />
        </div>
        {/* About Text End */}
        {/* About Image Start */}
        <div className="col-lg-5 col-md-10">
          <div className="about-image-wrap">
            <img className="img-full" src="/images/team/ASS.png" alt="About Us" />
          </div>
        </div>
        {/* About Image End */}
      </div>
    </div>
  </div>
  {/* about wrapper end */}
  {/* Begin Counterup Area */}
  <div className="counterup-area">
    <div className="container-fluid p-0">
      <div className="row no-gutters">
        <div className="col-lg-3 col-md-6">
          {/* Begin Limupa Counter Area */}
          <div className="limupa-counter white-smoke-bg">
            <div className="container">
              <div className="counter-img">
                <img src="https://i.ibb.co/QKXDBNM/1.png" alt="" />
              </div>
              <div className="counter-info">
                <div className="counter-number">
                  <h3 className="counter">29,265</h3>
                </div>
                <div className="counter-text">
                  <span>Follows this</span>
                </div>
              </div>
            </div>
          </div>
          {/* limupa Counter Area End Here */}
        </div>
        <div className="col-lg-3 col-md-6">
          {/* Begin limupa Counter Area */}
          <div className="limupa-counter gray-bg">
            <div className="counter-img">
              <img src="https://i.ibb.co/f1Zj6SL/2.png" alt="" />
            </div>
            <div className="counter-info">
              <div className="counter-number">
                <h3 className="counter">14,735</h3>
              </div>
              <div className="counter-text">
                <span>CUSTOMERS</span>
              </div>
            </div>
          </div>
          {/* limupa Counter Area End Here */}
        </div>
        <div className="col-lg-3 col-md-6">
          {/* Begin limupa Counter Area */}
          <div className="limupa-counter white-smoke-bg">
            <div className="counter-img">
              <img src="https://i.ibb.co/vBktQgS/3.png" alt="" />
            </div>
            <div className="counter-info">
              <div className="counter-number">
                <h3 className="counter">27/7</h3>
              </div>
              <div className="counter-text">
                <span>HOURS WORKED</span>
              </div>
            </div>
          </div>
          {/* limupa Counter Area End Here */}
        </div>
        <div className="col-lg-3 col-md-6">
          {/* Begin limupa Counter Area */}
          <div className="limupa-counter gray-bg">
            <div className="counter-img">
              <img src="https://i.ibb.co/z5t0Q7H/4.png" alt="" />
            </div>
            <div className="counter-info">
              <div className="counter-number">
                <h3 className="counter">28,356</h3>
              </div>
              <div className="counter-text">
                <span>Likes fanpage</span>
              </div>
            </div>
          </div>
          {/* limupa Counter Area End Here */}
        </div>
      </div>
    </div>
  </div>
  {/* Counterup Area End Here */}
  {/* team area wrapper start */}
  <div className="team-area pt-60 pt-sm-44">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="li-section-title capitalize mb-25">
            <h2><span>Sản phẩm đại diện của chúng tôi</span></h2>
          </div>
        </div>
      </div> {/* section title end */}
      <div className="row">
        <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="team-member mb-60 mb-sm-30 mb-xs-30">
            <div className="team-thumb">
              <img src="/images/team/270-hoco.jpg" alt="Our Team Member" />
            </div>
            <div className="team-content text-center">
              <h3>HOCO.</h3>
              <p>Sản phẩm HOCO</p>
              <div className="team-social">
                <a href="https://facebook.com/phukienasang"><i className="fa fa-facebook" /></a>
                <a href="/"><i className="fa fa-twitter" /></a>
                <a href="/"><i className="fa fa-linkedin" /></a>
                <a href="https://g.page/r/CbhARUWoWwtuEAE"><i className="fa fa-google-plus" /></a>
              </div>
            </div>
          </div>
        </div> {/* end single team member */}
        <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="team-member mb-60 mb-sm-30 mb-xs-30">
            <div className="team-thumb">
              <img src="/images/team/270-byz.jpg" alt="Our Team Member" />
            </div>
            <div className="team-content text-center">
              <h3>BYZ.</h3>
              <p>Sản phẩm BYZ</p>
              <div className="team-social">
                <a href="https://facebook.com/phukienasang"><i className="fa fa-facebook" /></a>
                <a href="/"><i className="fa fa-twitter" /></a>
                <a href="/"><i className="fa fa-linkedin" /></a>
                <a href="https://g.page/r/CbhARUWoWwtuEAE"><i className="fa fa-google-plus" /></a>
              </div>
            </div>
          </div>
        </div> {/* end single team member */}
        <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="team-member mb-30 mb-sm-60">
            <div className="team-thumb">
              <img src="/images/team/270-remax.jpg" alt="Our Team Member" />
            </div>
            <div className="team-content text-center">
              <h3>Remax.</h3>
              <p>Sản phẩm REMAX</p>
              <a href="/"></a>
              <div className="team-social">
                <a href="https://facebook.com/phukienasang"><i className="fa fa-facebook" /></a>
                <a href="/"><i className="fa fa-twitter" /></a>
                <a href="/"><i className="fa fa-linkedin" /></a>
                <a href="https://g.page/r/CbhARUWoWwtuEAE"><i className="fa fa-google-plus" /></a>
              </div>
            </div>
          </div>
        </div> {/* end single team member */}
        <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="team-member mb-30 mb-sm-60 mb-xs-60">
            <div className="team-thumb">
              <img src="/images/team/270-kingkong.jpg" alt="Our Team Member" />
            </div>
            <div className="team-content text-center">
              <h3>KingKong.</h3>
              <p>Sản phẩm KINGKONG</p>
              <a href="/"></a>
              <div className="team-social">
                <a href="https://facebook.com/phukienasang"><i className="fa fa-facebook" /></a>
                <a href="/"><i className="fa fa-twitter" /></a>
                <a href="/"><i className="fa fa-linkedin" /></a>
                <a href="https://g.page/r/CbhARUWoWwtuEAE"><i className="fa fa-google-plus" /></a>
              </div>
            </div>
          </div>
        </div> {/* end single team member */}
      </div>
    </div>
  </div>
</div>

    )
  }
}

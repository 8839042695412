import * as Types from '../../constants/ActionType';
let initialState = {};

const productsSearch = (state = initialState, action) => {
    switch (action.type) {
        case Types.FETCH_PRODUCTS_SEARCH:
            state = action.products;
            return state;
        case Types.FETCH_MORE_PRODUCTS_SEARCH:
            state = {
                ...action.products,
                results: [...state.results, ...action.products.results]
            };
            return state;
        default: return state;
    }
};

export default productsSearch;
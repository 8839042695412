import React, { Component } from 'react';
import ProductByCategory from './ProductByCategory';
import {connect} from 'react-redux';
import { actFetchProductsByCategoriesRequest } from '../../../../redux/actions/products';
import LazyLoad from 'react-lazyload';
import Spinner from 'components/common/Spinner/Spinner';

class ProductByCategories extends Component {

  componentDidMount() {
    this.props.fetch_products_categories()
  }

  render() {
    const { categories } = this.props;
    return (
      <div className="ProductByCategories">
        {categories && categories.length > 0 &&
          categories.map((category, index) => (
            <LazyLoad
              placeholder={<Spinner className="container Product-category"></Spinner>}
            >
              <ProductByCategory category = {category}/>
            </LazyLoad>
          ))
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.productByCategories
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetch_products_categories: () => {
      dispatch(actFetchProductsByCategoriesRequest())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductByCategories)
import React, { Component } from 'react';
import {config} from 'config'
import './style.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default class SliderLeft extends Component {

  render() {
    const {banners} = this.props;

    return (
      <div className="col-lg-9 col-md-9">
        <div className="slider-area">
          <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
            {
              banners.map((banner, index) => {
                return (
                  <div className={index === 0 ? "carousel-item active": "carousel-item"} key={index}>
                    <LazyLoadImage
                      effect="blur"
                      src={`${config.api.url_BE}/${banner.url}`} 
                      alt="img-banner"
                      className="d-block w-100"
                    />
                  </div>
                )
              })
            }
            </div>
            <a className="carousel-control-prev" href="/categories/38" role="button" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="/categories/38" role="button" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    )
  }
}

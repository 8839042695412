import React, { Component} from "react";
import routes from "./routes";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import TabBottom from './components/TabBottom/BottomPanel';
import { actFetchCartRequest } from "./redux/actions/cart";
import { connect } from "react-redux";
import { actTokenRequest } from "./redux/actions/auth";
import Social from "./components/Social/Social";
import { actFetchFavoritesRequest } from "./redux/actions/rating";
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import { actShowLoading } from "./redux/actions/loading";
import callApi from "./utils/apiCaller";
import './style.css'
import './custom_style.css'
import 'assets/style/responsive.css';
import ReactGA from "react-ga4";
const override = css`
    display: block; 
    margin: 0px -10px; 
    position: fixed; 
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999; !important;
`;
 // Đặt tên cho cookie
const cookieName = "AppASang";
//GA measurementID

const configArray = [
  { measurementId: 'G-3C4S6NLK28' }, //phukienasang.com
  { measurementId: 'G-7S4SDSGEWT' }, //byzvietnam.vn
  { measurementId: 'GTM-K6JKMJ6' }, //GTag
  // Thêm các cấu hình khác nếu cần
];


class App extends Component {
  componentDidMount() {
    // Kiểm tra xem cookie đã được đặt hay chưa
  const isCookieSet = localStorage.getItem("isCookieSet");

  if (!isCookieSet) {
   
    // Xóa cookies khi ứng dụng được tải lần đầu tiên
  if (!localStorage.getItem("isAppLoaded")) {
    // Xóa các cookies tại đây
    localStorage.removeItem(cookieName);

    // Đánh dấu rằng ứng dụng đã được tải lần đầu
    localStorage.setItem("isAppLoaded", "true");
  }
    
    // Lưu tên cookie vào localStorage
    localStorage.setItem("cookieName", cookieName);

    // Đánh dấu rằng cookie đã được đặt
    localStorage.setItem("isCookieSet", "true");
  }
    //this.checkAuth();
    let isCart = false;
    this.props.fetch_cart();
  
    if (localStorage.length === 0) {
      localStorage.setItem("_cart", JSON.stringify([]));
    }
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i) === "_cart") {
        isCart = true;
      }
    }
    if (!isCart) {
      localStorage.setItem("_cart", JSON.stringify([]));
    }
  
    // Set GA4 Google Analytics
    
    configArray.forEach(config => {
      ReactGA.initialize(config.measurementId);
    });
   // ReactGA.initialize('G-3C4S6NLK28');
    ReactGA.send('pageview');
  }
  

  checkAuth = async () => {
    const token = localStorage.getItem("_auth");
    const isAuth = await callApi("users/me", "GET", null, token);
    if(isAuth.status === 200){
      if (token && token.length > 0) {
        this.props.add_token_redux(token);
        this.props.fetch_favorite(token);
      }
    } else{
      localStorage.removeItem('_auth');
      this.props.add_token_redux(null);
    }
  }
 

   // Detects if device is on iOS
isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod|macintosh/.test(userAgent);
}
                              
// Detects if device is on iOS
isSafari = () => {
  return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') === -1 &&
      navigator.userAgent.indexOf('FxiOS') === -1;
}         

  render() {
    const {loading} = this.props;
    return ( 
      <Router> 
        {
          !loading ? 
          <div>
            <div className='sweet-loading'>
            <ClipLoader
              css={override}
              sizeUnit={"px"}
              size={35}
              color={'#fdfdfd00'}
              loading={loading}
            />
          </div>
          <Social></Social>
          <Header></Header>
          {this.showContentMenus(routes)}
          <Footer></Footer>
          <TabBottom />          
          </div>
          : 
          <div className="hidden-loading">
            <div className='sweet-loading'>
              <ClipLoader
                css={override}
                sizeUnit={"px"}
                size={35}
                color={'#fdfdfd00'}
                loading={loading}
              />
            </div>
            <Social></Social>
            <Header></Header>
            {this.showContentMenus(routes)}
            <Footer></Footer>
            <TabBottom />            
          </div>
        }
         
                
      </Router>      
    );
  }
  
  showContentMenus = routes => {
    let result = null;
    if (routes.length > 0) {
      result = routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.main}
            onClick={() => {
              ReactGA.event({
                category: 'Product',
                action: 'Click',
                label: 'Product Name',
              });
              ReactGA.event({
                category: 'Homepage',
                action: 'page_view'
              });
              ReactGA.event({
                category: 'HomePage',
                action: 'first_visit'
              });
              ReactGA.event({
                category: 'HomePage',
                action: 'scroll'
              });
              ReactGA.event({
                category: 'HomePage',
                action: 'click'
              });
              ReactGA.event({
                category: 'products',
                action: 'click'
              });
              ReactGA.event({
                category: 'Product Click',
                action: 'Image Click'
              });
            }}            
          />
        );
      });
    }
    return <Switch>{result}</Switch>;
  };
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetch_cart: () => {
      dispatch(actFetchCartRequest());
    },
    fetch_favorite: token => {
      dispatch(actFetchFavoritesRequest(token));
    },
    add_token_redux: token => {
      dispatch(actTokenRequest(token));
    },
    statusLoading: () => {
      dispatch(actShowLoading());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
import * as Types from './../../constants/ActionType';
let initialState = -1;

const location = (state = initialState, action) => {
    switch (action.type) {
        case Types.FETCH_LOCATION:
            state = action.location;
            return state;
        default: return state;
    }
};

export default location;
import React from "react";
import Slider from 'react-slick';
import ReactImageMagnify from 'react-image-magnify';
import Lightbox from 'react-image-lightbox';
import { useState } from 'react';
import MagicSliderDots from 'react-magic-slider-dots';
import 'react-magic-slider-dots/dist/magic-dots.css';
import 'react-image-lightbox/style.css';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import './ImgSlider.css';

import {config} from 'config';

const ImgSlider = (props) => {
  const {images} = props;
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);

  let imgSource = images.map((img, index) => (
    `${config.api.url_BE}/${img}`
  ))

  // const handleDots = (i) => {
  //   return <img src={imgSource[i]} alt={i} key={i} />;
  // };

  const handleChange = (current, next) => {
    setIndex(next);
  };

  
  const settings = {
    
     // customPaging: handleDots,
      dots: true,
      dotsClass: 'slick-dots slick-thumb',
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: handleChange,
      // focusOnSelect: true
      appendDots: dots => {
        return <MagicSliderDots dots={dots} numDotsToShow={6} dotWidth={30} />;
      }

       
  };
 
  return (
    <div className='S_image'>
      <button className='zoom' onClick={() => setOpen(true)}>
        <ZoomInIcon style={{color:"#fff"}}/>
      </button>
      {open && (
        <Lightbox
          mainSrc={imgSource[index]}
          nextSrc={imgSource[(index + 1) % imgSource.length]}
          prevSrc={
            imgSource[(index + imgSource.length - 1) % imgSource.length]
          }
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            setIndex((index + imgSource.length - 1) % imgSource.length)
          }
          onMoveNextRequest={() => setIndex((index + 1) % imgSource.length)}
        />
      )}
      <Slider {...settings} >
        {imgSource.map((src, index) => (
          <div key={index}>
            <ReactImageMagnify
              {...{
                smallImage: {
                  alt: src,
                  isFluidWidth: true,
                  src: src,
                  srcSet: src,
                   sizes:
                     '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px',
                },
                largeImage: {
                  src: src,
                  width: 1200,
                  height: 1200,
                },
                lensStyle: { backgroundColor: 'rgba(0,0,0,0.1)' },
                hoverDelayInMs: 0,
                enlargedImagePortalId: 'portal',
                enlargedImageContainerDimensions: {
                  width: '180%',
                  height: '100%',
                },
              }}
            />
          </div>
        ))}
      </Slider>
      {/*Slide thumbnail*/}
      
    </div>
  );
};

export default ImgSlider;
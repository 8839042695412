import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { createStore, applyMiddleware } from 'redux';
import appReducers from './redux/reducers/index';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { ToastContainer } from 'react-toastify';
import 'nprogress/nprogress.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MessengerCustomerChat from 'react-messenger-customer-chat';
import TagManager from 'react-gtm-module';
import registerServiceWorker from './registerServiceWorker';
const store = createStore(appReducers, composeWithDevTools(
  applyMiddleware(thunk)
));
const tagManagerArgs = {
  gtmId: 'GTM-K6JKMJ6'
}
TagManager.initialize(tagManagerArgs)
ReactDOM.render(
  <Provider store={store}>
    <App />
    <ToastContainer
      position="top-right"
      autoClose={1700}
      pauseOnHover={false}
      pauseOnVisibilityChange={false}
    />
    <MessengerCustomerChat
           pageId="387495331676182" 
            appId="1042270453077683"
            htmlRef={window.location.pathname}
            setAdvertiserIDCollectionEnabled={true}
            xfbml={true}
            language="vi_VN"
     />
  </Provider>,
  window.document.getElementById('root'));
 registerServiceWorker();

import React, { Component } from 'react'
import LeftOptions from './LeftOptions'
import ShopCategoryItems from './ShopCategoryItems'
import ProductItemMobile from '../common/ProductItemMobile/ProductItemMobile';
import ProductItemFlex from '../common/ProductItemFlex/ProductItemFlex';
import { connect } from 'react-redux'
import { actGetProductOfCategoryRequest, actGetMoreDataRequest  } from '../../redux/actions/products';
import {actFetchLocation} from '../../redux/actions/location';
import InfiniteScroll from "react-infinite-scroll-component";

class ShopCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      sort: '-createAt',
      price: null,
      isGrid: true,
      isHasMore: true
    };
  }
  
  componentDidMount() {
    this.fetch_reload_data();
    this.props.fetch_location(this.props.id);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.id !== this.props.id) {
      this.setState({
        currentPage: 1,
        sort: '-createAt',
        price: null,
        isGrid: true,
        isHasMore: true
      })
    }
  }

  fetch_reload_data(){
    this.props.fetch_products(this.props.id, this.state.sort)
  }

  totalProducts(total){
    this.setState({
      total
    })
  }
  
  fetchMoreData(){
    const {currentPage} = this.state;
    const {products} = this.props;

    const limit = 12;
    const offset = limit * (currentPage);
    if( (Math.floor(products.total/limit) +1) > currentPage) {
      this.setState({
        currentPage: currentPage + 1
      })
      this.props.fetch_moreDate(this.props.id, this.state.sort, offset, this.state.price);
    } 
    if(Math.floor(products.total/limit) === currentPage) {
      this.setState({
        isHasMore: false
      })
    }
  }

  handleChangeSelectSort = (event, categoryId) => {
    const offset = 0;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({
      sort: value,
      currentPage: 1,
      isHasMore: true
    })
    this.props.fetch_products(categoryId, value, offset, this.state.price);
  }

  handleFilterPrice = (price) => {
    const offset = 0;
    this.setState({
      price: price,
      currentPage: 1,
      isHasMore: true
    })
    this.props.fetch_products(this.props.id, this.state.sort, offset, price)
    .then(res => {
      this.setState({
        total: res.total
      });
    }).catch(err => {
      console.log(err);  
    })
  }

  handleChangeView = () => {
    this.setState({
      isGrid: !this.state.isGrid
    })
  }

  handleFilterAll = () => {
    // Bỏ tất cả điều kiện lọc
    this.setState({
      sort: '-createAt',
      price: null,
    })
  }

  render() {
    const { products } = this.props;
    const {isHasMore} = this.state;
    if (products.results && products.results.length) {
      products.results.map((item) => this.props.id === item.categoryId)
    }

    return (
      <div className="content-wraper ShopCategory pb-60">
        <div className="container">
          <div className="row categoryPage">
            <LeftOptions
              totalProducts = {(count)=>this.totalProducts(count)} 
              id={this.props.id} 
              categoryId={this.props.id}
              handleFilterPrice = {this.handleFilterPrice}
              sort={this.state.sort}
              price={this.state.price}
              handleFilterAll = {this.handleFilterAll}
            ></LeftOptions>
            <div className="col-lg-9 order-1 order-lg-2">
              <InfiniteScroll
                dataLength={products.results && products.results.length || 12}
                next={this.fetchMoreData.bind(this)}
                hasMore={products.total && products.total > 12 ? isHasMore : false}
                loader={<p style={{textAlign:'center'}}>Loading...</p>}
              >
                {/* Begin Li's Banner Area */}
                <div className="single-banner shop-page-banner">
                  <a href="/">
                    <img src="/images/bg-banner/2.jpg" alt="Li's Static Banner" />
                  </a>
                </div>
                <div className="shop-top-bar mt-30">
                  <div className="shop-bar-inner">
                    <div className="product-view-mode">
                      {/* shop-item-filter-list start */}
                      <ul className="nav shop-item-filter-list" role="tablist">
                        <li role="presentation" className="desktop"><a data-toggle="tab" role="tab" aria-controls="grid-view"><i className="fa fa-th" /></a></li>
                        <li role="presentation" className="mobile-flex" onClick={this.handleChangeView}><a data-toggle="tab" role="tab" aria-controls="grid-view"><i className="fa fa-th" /></a></li>
                      </ul>
                    </div>
                    <div className="toolbar-amount">
                      {/* <span>Hiển thị 12 sản phẩm</span> */}
                    </div>
                  </div>
                  <div className="product-select-box">
                    <div className="product-short">
                      <p>Sắp xếp:</p>
                      <select className="nice-select" onChange={(event) => this.handleChangeSelectSort(event, this.props.id)} >
                        <option value="createAt">Tất cả</option>
                        <option value="nameProduct">Tên (A - Z)</option>
                        <option value="-nameProduct">Tên (Z - A)</option>
                        <option value="price">Giá (Thấp &gt; Cao)</option>
                        <option value="-price">Giá (Cao &gt; Thấp)</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="shop-products-wrapper">
                  <div className="tab-content">
                    <div id="grid-view" className="desktop">
                      <div className="product-area shop-product-area">
                        <div className="row">
                          {products.results && products.results.map((product, index) => {
                            const category = product.categoryId_categories.find(item => item.id === parseInt(this.props.id));
                            return (
                              <ShopCategoryItems
                                key={index} 
                                product={product} 
                                category = {category}
                              ></ShopCategoryItems>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                    {
                      this.state.isGrid 
                      ? <div className="grid-view-mobile mobile-grid">
                        {/* Khu vực grid 3 colum */}
                        {products.results &&  products.results.length > 0 && products.results.map((product, index) => {
                          const category = product.categoryId_categories.find(item => item.id === parseInt(this.props.id));
                          return (
                            <ProductItemMobile 
                              key={index} 
                              product={product} 
                              category = {category} 
                            />
                          )
                        })}
                      </div>
                      : <div className="mobile-flex flex-view-mobile">
                        {/* Khu vực product view flex */}
                        {products.results && products.results.length > 0 && products.results.map((product, index) => {
                          const category = product.categoryId_categories.find(item => item.id === parseInt(this.props.id));
                          return (
                            <ProductItemFlex 
                              key={index} 
                              product={product} 
                              category = {category} 
                            />
                          )
                        })}
                      </div> 
                    }
                  </div>
                </div>
              </InfiniteScroll> 
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.products
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetch_products: (id, value, offset, price) => {
      return dispatch(actGetProductOfCategoryRequest(id, value, offset, price));
    },
    fetch_moreDate: (id, value, offset, price) => {
      return dispatch(actGetMoreDataRequest(id, value, offset, price));
    },
    fetch_location: (id) => {
      return dispatch(actFetchLocation(id))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopCategory)


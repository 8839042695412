import callApi from './apiCaller';

export async function uploadImage(image, token) {
  let data = new FormData();
  data.append("image", image);
  const res = await callApi('upload', 'POST', data, token);
  if (res && res.status === 200) {
    return res.data;
  }
}


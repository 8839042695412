import React, { Component } from 'react'
import HistoryBooking from 'components/HistoryBooking/HistoryBooking';
import LinkHere from '../components/LinkHere/LinkHere'

export default class HistoryBookingPage extends Component {
  render() {
 
    return (
      <div>
        <LinkHere title='Quản lý đơn hàng'></LinkHere>
        <HistoryBooking />
      </div>
    )
  }
}

import * as React from 'react';
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { startLoading, doneLoading } from 'utils/loading';
import { actGetProductOfCategoryRequest } from 'redux/actions/products';
import { actFetchLocation } from 'redux/actions/location';

export default function MenuCategories(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const {categories} = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const redirect = (category_id) => {
        return async () => {
            startLoading();
            await dispatch(actGetProductOfCategoryRequest(category_id, '-createAt'))
            await dispatch(actFetchLocation(category_id))
            doneLoading();
            history.push(`/categories/${category_id}`)
        }
    }

    return (
        <Box sx={{ maxWidth: "100%", bgcolor: 'background.paper' }}>
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
            >
                {
                    categories.map((category, index) =>(
                        <Tab 
                            label={category.nameCategory} 
                            key={index}
                            onClick={redirect(category.id)}
                        />
                    )) 
                }  
            </Tabs>
        </Box>
    );
}
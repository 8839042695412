import React, { Component } from 'react'
import LinkHere from '../components/LinkHere/LinkHere'
import Login from '../components/Login/Login'

export default class LoginPage extends Component {
  render() {
    
    return (
      <div>
        <LinkHere title="Đăng nhập"></LinkHere>
        <Login></Login>
      </div>
    )
  }
}

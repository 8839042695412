import React, { Component } from 'react'
import LinkHere from '../components/LinkHere/LinkHere'
import ProductAll from '../components/ProductAll/ProductAll'
export default class ProductPage extends Component {
  render() {

    return (
      <div>
        <LinkHere title="Sản phẩm"></LinkHere>
        <ProductAll></ProductAll>
      </div>
    )
  }
}


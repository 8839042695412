export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_MORE_ALLPRODUCTS = 'FETCH_MORE_ALLPRODUCTS'
export const FETCH_PRODUCTS_NEW = 'FETCH_PRODUCTS_NEW';
export const FETCH_PRODUCTS_CATEGORIES = 'FETCH_PRODUCTS_CATEGORIES';
export const FETCH_PRODUCTS_OFFICE = 'FETCH_PRODUCTS_OFFICE';
export const FETCH_PRODUCTS_OHTER = 'FETCH_PRODUCTS_OHTER';
export const FETCH_PRODUCTS_SEARCH = 'FETCH_PRODUCTS_SEARCH'
export const FETCH_MORE_PRODUCTS_SEARCH = 'FETCH_MORE_PRODUCTS_SEARCH'
export const FETCH_LOCATION = 'FETCH_LOCATION';
export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS';
export const FETCH_MORE_DATA = 'FETCH_MORE_DATA';
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';

export const FETCH_CATEGORIES_PRODUCT = 'FETCH_CATEGORIES_PRODUCT';

export const STATUS_CATEGORY = "STATUS_CATEGORY";

export const FETCH_CART = "FETCH_CART";
export const ADD_CART = "ADD_CART";
export const UPDATE_CART = "UPDATE_CART";
export const REMOVE_CART = "REMOVE_CART";
export const CLEAR_CART = " CLEAR_CART";

export const LOGIN = 'LOGIN';
export const REGISTER = 'REGISTER';
export const TOKEN_REDUX = 'TOKEN_REDUX';
export const UPDATE_ME = 'UPDATE_ME';

export const FETCH_RATINGS = "FETCH_RATINGS";
export const ADD_RATING = "ADD_RATING";
export const UPDATE_RATING = "UPDATE_RATING";
export const REMOVE_RATING = "REMOVE_RATING";

export const FETCH_FAVORITES = "FETCH_FAVORITES";
export const ADD_FAVORITE = "ADD_FAVORITE";
export const UPDATE_FAVORITE = "UPDATE_FAVORITE";
export const REMOVE_FAVORITE = "REMOVE_FAVORITE";

export const FETCH_PRODUCERS = "FETCH_PRODUCERS";
export const FETCH_PRODUDCTS_PRODUCER = "FETCH_PRODUDCTS_PRODUCER";

export const SHOWLOADING = "SHOWLOADING";
export const HIDDENLOADING = "HIDDENLOADING";

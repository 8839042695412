import React from 'react';
import { useSelector } from "react-redux";
import ProductItem from '../../../common/ProductItemMobile/ProductItemMobile';
import Slider from "react-slick";

const ProductsMobile = () => {
    const products = useSelector(state => state.productsNew);
    const settings = {
        initialSlide: 6,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        cssEase: "linear",
        responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 6,
                slidesToScroll: 4,
                infinite: true,
                dots: false
              }
            },
            {
              breakpoint: 960,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 3,
                infinite: true,
                dots: false
              }
            },
            {
              breakpoint: 760,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 3,
                infinite: true,
                dots: false
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            }
          ]
    };

    return (
        <div className="ProductsMobile">
            {
                <Slider {...settings}>
                    {products && products.length ? products.map((product, index) => {
                        return (
                          <ProductItem 
                            product={product} 
                            key={index}
                            category = {
                                {
                                    'id': product.categoryId_categories && product.categoryId_categories.length > 0 ? product.categoryId_categories[0].id : '', 
                                    'nameCategory': product.categoryId_categories && product.categoryId_categories.length > 0 ? product.categoryId_categories[0].nameCategory : ''
                                }
                            }
                          />
                        )
                        }) : null
                    }
                </Slider>
            }
        </div>
    );
};

export default ProductsMobile;

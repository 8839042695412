import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { actFetchCategoriesRequest } from '../../redux/actions/category';
import { actGetProductOfCategoryRequest, actFetchProductsRequest } from '../../redux/actions/products';
import { startLoading, doneLoading } from '../../utils/loading'
import { actFetchProducersRequest } from '../../redux/actions/producer';
import {actFetchLocation} from '../../redux/actions/location';
// import LogoHome from 'assets/images/LogoHome.jpg';
import MenuCategories from './MenuCategories';
import { AiFillPhone, AiOutlineMenuUnfold } from 'react-icons/ai';
class HeaderBottom extends Component {

  componentDidMount() {
    this.props.fetchAllcategories();
  }

  getIdCategory = async (id) => {
    startLoading();
    await this.props.getAllProductOfCategory(id);
    doneLoading();
    this.props.fetchLocation(id);

  }

  loaddingPage = () => {
    startLoading();
    doneLoading();
  }

  render() {
    const { categories } = this.props;
    return (
      <div className="header-bottom header-sticky d-lg-block d-xl-block desktop" >
        <div className="container header-botom-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="hb-menu">
                <nav className="navbar navbar-expand-sm navbar-light">
                  <Link className="navbar-brand" to="/"><img src={"/images/menu/logo/icons8-home-30.png"} alt="not found" style={{height: 30, width: 30}}/></Link>
                  <button className="navbar-toggler d-lg-none" type="button" data-toggle="collapse" data-target="#collapsibleNavId" aria-controls="collapsibleNavId" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                  </button>
                  <div className="collapse navbar-collapse" id="collapsibleNavId">
                    <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                      <li className="nav-item active">
                        <Link className="nav-link" to="/">TRANG CHỦ</Link>
                      </li>
                      {/* <li className="nav-item dropdown">
                        <Link className="nav-link dropdown-toggle" to="#" id="dropdownId" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">DANH MỤC</Link>
                        <div className="dropdown-menu" aria-labelledby="dropdownId">
                        {categories.map((category, index) => 
                            <Link key={index} className="dropdown-item" onClick={() => this.getIdCategory(category.id)} to={`/categories/${category.id}`}>{category.nameCategory}</Link>
                        )}
                        </div>
                      </li> */}
                      <li className="nav-item">
                        <Link onClick={() => this.loaddingPage()} className="nav-link" to="/products">SẢN PHẨM</Link>
                      </li>
                      <li className="nav-item">
                        <Link onClick={() => this.loaddingPage()} className="nav-link" to="/contact">BÁO GIÁ</Link>
                      </li>
                      <li className="nav-item">
                        <Link onClick={() => this.loaddingPage()} className="nav-link" to="/cart">THANH TOÁN</Link>
                      </li>
					   <li className="nav-item">
                        <Link onClick={() => this.loaddingPage()} className="nav-link" to="/about">GIỚI THIỆU - CHỨNG NHẬN</Link>
                      </li>	
                    </ul>
                  </div>
                </nav>
                <div className="List_categories">
                  {/* <AiOutlineMenuUnfold className="icon_categories"/> */}
                  
                  {/* {
                    categories.map((category, index) => 
                      <Link 
                        key={index} 
                        onClick={() => this.getIdCategory(category.id)} 
                        to={`/categories/${category.id}`}
                        className="category_menu"
                      >{category.nameCategory}<span>|</span></Link>
                    )
                  } */}
                  <MenuCategories categories={categories}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    categories: state.categories
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchLocation: (id) => {
      dispatch(actFetchLocation(id))
    },
    fetchAllcategories: () => {
      dispatch(actFetchCategoriesRequest());
    },
    getAllProductOfCategory: (id) => {
      dispatch(actGetProductOfCategoryRequest(id, '-createAt'));
    },
    fetch_products: (q, idCateogry) => {
      dispatch(actFetchProductsRequest(q, idCateogry));
    },
    fetch_producers: (id) => {
      dispatch(actFetchProducersRequest(id))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderBottom);

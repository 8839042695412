import React, { Component } from 'react';
// Edit
import {Link} from 'react-router-dom'
///
import callApi from '../../utils/apiCaller';
import { formatNumber } from '../../config/TYPE'
import './style.css';
import 'react-toastify/dist/ReactToastify.css';
import SumTotal from './SumTotal';
import CartItemMobile from './CartItemMobile/CartItemMobile';
import './style.css';

let token;

class HistoryBookingDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      order: []
    }
  }

  async componentDidMount() {
    token = localStorage.getItem('_auth');
    const res = await callApi(`orders/${this.props.id}/orderDetails`, 'GET', null, token);
    console.log('order detail', res.data.results)
    this.setState({
      order: res.data.results
    })
  }

  render() {
    const { order } = this.state;

    return (
      <div className="booking-area">
        <h3 className="title-history-booking">Chi tiết đơn hàng</h3>
        <div className="container">
          <div className="row">
            <div className="col-sm-8 col-xs-12 desktop">
              <form>
                <div className="table-content table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="li-product-remove">stt</th>
                        <th className="cart-product-name">Sản phẩm</th>
                        <th className="li-product-price">Giá</th>
                        <th className="li-product-quantity">Số lượng</th>
                        <th className="li-product-quantity">Ghi chú</th>
                        <th className="li-product-subtotal">Tổng tiền</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        order && order.length ? order.map((item, index) => {
                          return (
                            <tr>
                              <td className="li-product-remove">{index + 1}</td>
                              <td className="li-product-name">
                                {/* Edit */}
                                <Link className="text-dark" to={`/products/${item.product.id}`}>{item.nameProduct}</Link>
                                {
                                  item.product.categoryId_categories && 
                                  <div className="cart-categories">
                                    { 
                                      item.product.categoryId_categories.map((category, index) => {
                                        if(index === item.product.categoryId_categories.length - 1) {
                                          return <Link to={`/categories/${category.id || ''}`} key={index} className="cart-category">{ category.nameCategory || ''}</Link>
                                        }
                                        return <Link to={`/categories/${category.id || ''}`} key={index} className="cart-category">{`${category.nameCategory}, ` || ''}</Link>
                                      })
                                    }
                                  </div>
                                }
                                {/* End */}
                              </td>
                              <td className="product-subtotal">
                                <span className="amount">
                                {
                                  item.product.discountPrice 
                                  ? <div className="box-price">
                                    <span className="price-lineThought">{formatNumber.format(item.product.price)}</span>
                                    <span>{formatNumber.format(item.product.discountPrice)}</span>
                                  </div>
                                  :<span>{formatNumber.format(item.product.price)}</span>
                                }
                                </span>
                              </td>
                              <td className="quantity">
                                <span>{item.quantity}</span>
                              </td>
                              <td>{item.note}</td>
                              <td className="product-subtotal">
                                <span className="amount">
                                  {
                                    item.product.discountPrice 
                                    ? formatNumber.format(item.product.discountPrice * item.quantity)
                                    : formatNumber.format(item.product.price * item.quantity)
                                  }
                                </span>
                              </td>
                            </tr>
                          )
                        }) : null  
                      }   
                    </tbody>
                  </table>
                </div>
              </form>
            </div>
            <div className="mobile-flex history-detail-mobile">
              {
                order && order.length ? order.map((item, index) => (
                  <CartItemMobile key={index} item={item}/>
                )) : null
              }
            </div>
            <div className="col-sm-4 col-xs-12 sumTotal">
                {order && <SumTotal orderDetail = {order}></SumTotal>}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default HistoryBookingDetail;

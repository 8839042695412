import React, { Component } from 'react'
import {config} from 'config'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default class BannerRight extends Component {
  render() {
    const {banners} = this.props;
    console.log(banners)
    return (
      <div className="col-lg-3 col-md-3 text-center pt-xs-30 desktop banner-right">
        {
          banners[0] && 
          <div className="li-banner">
            <Link tp="#">
              <LazyLoadImage
                effect="blur"
                src={`${config.api.url_BE}/${banners[0].url}`} 
                alt="not found"
              />
            </Link>
          </div>
        }
        {
          banners[1] && 
          <div className="li-banner mt-15 mt-sm-30 mt-xs-30">
            <Link tp="#">
              <LazyLoadImage
                effect="blur"
                src={`${config.api.url_BE}/${banners[1].url}`} 
                alt="not found"
              />
            </Link>
          </div>
        }
        
      </div>
    )
  }
}

import React, { Component } from "react";
import {Link} from 'react-router-dom'
import BillDetail from "./BillDetail";
import YourOrder from "./YourOrder";
import { connect } from "react-redux";
import callApi from "../../utils/apiCaller";
import { Redirect } from "react-router-dom";
import { actClearRequest } from "../../redux/actions/cart";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { startLoading, doneLoading } from "../../utils/loading";
import { actUpdateMeRequset } from '../../redux/actions/auth';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "./style.css";
const MySwal = withReactContent(Swal);

toast.configure();

let token, res, resultOrder;
class CheckOut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleCheckout: false,
      login: true,
      shippingAddress: true,
      checkout: false,
      result: false,
    };
    this.billing = React.createRef();
  }

  submitOrder = async () => {
    MySwal.fire({
      title: "Bạn chắc chắn?",
      text: "bạn muốn đặt đơn hàng ngay bây giờ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "có, Đặt đơn ngay!",
      cancelButtonText: "Không",
    }).then(async (result) => {
      if (res.name === "" || res.address === "" || res.phone === "") {
        return toast.error("Điền đầy đủ thông tin trước khi đặt đơn");
      }
      const cart = this.props.cartStore;
      if (result.value) {
        const { provinceData, stateData } = res; //get code
        let resData = await callApi(`users/${res.phone}/getByPhone`, 'GET', null);
        if(resData.status === 404) {
          resData = await callApi(`auth/register`, 'POST', {
            name: res.name || '',
            address: res.address || '',
            email: res.email || '',
            phone: res.phone || ''
          });
        }
        const userId = resData.data.id;
       
        //GET DATA FOR TABLE ORDER
        let sumPrice = 0;
        let sumDiscount = 0;
        
        if (cart.length > 0) {
          sumPrice = cart.reduce((sum, item) => {
            return sum += item.quantity * item.price
          }, 0)

          sumDiscount = cart.reduce((sum, item) => {
            if(item.discountPrice) {
              return sum += item.quantity * (item.price - item.discountPrice)
            }
            return sum
          }, 0)
        } //output total Amount
        let addressProvince;
        if (res.provinces && res.provinces.length) {
          res.provinces.map((item) => {
            if (item.code === provinceData) {
              addressProvince = item.name;
              return addressProvince;
            }
            return { message: "error" };
          }); //output name province
        }

        let addressState;
        if (res.states && res.states.length) {
          res.states.map((item) => {
            if (item.code === stateData) {
              addressState = item.name;
              return addressState;
            }
            return { message: "error" };
          }); //output name state
        }

        const addressResult = {
          province: addressProvince,
          state: addressState,
          house: res.address,
          codeProvince: provinceData,
          codeState: stateData,
        }; // output address
        const note = res.note !== "" ? res.note : null;
        const resultOrder = {
          fullname: res.name,
          province: addressResult.province,
          codeProvince: addressResult.codeProvince,
          codeState: addressResult.codeState,
          state: addressResult.state,
          house: addressResult.house,
          note: note,
          phone: res.phone,
          totalPrice: sumPrice,
          totalDiscountPrice: sumDiscount,
          userId
        };
        //insert order to db
        startLoading();
        const orderDb = await callApi("orders", "POST", resultOrder, token); //method post nen truyen them token tren headers
        //END GET DATA FOR TABLE ORDER

        //GET DATA ORDER_DETAIL
        cart.map(async (item) => {
          const resultOrderDetail = {
            quantity: item.quantity,
            totalPrice: item.price*item.quantity,
            totalDiscountPrice: item.discountPrice ? (item.price - item.discountPrice)*item.quantity : 0,
            orderId: orderDb.data.id,
            productId: item.id,
            nameProduct: item.nameProduct,
            note: item.note
          };
          
          await callApi(
            "orderDetails",
            "POST",
            resultOrderDetail,
            token
          );
        });

        MySwal.fire({
          position: "top-end",
          icon: "success",
          title: "Đặt hàng thành công!",
          showConfirmButton: true,
          timer: 15000,
        });
        callApi(
          `orders/sendOrderDetail/${orderDb.data.id}`,
          "POST",
          {},
          token
        );
        this.setState({
          checkout: true,
          result: true,
        });
        doneLoading();
        //ENDGET DATA ORDER_DETAIL

        //CLEAR CART AFTER CHECKOUT
        this.props.reset_cart();
      }
    });
  };

  toggleCheckout = async () => {
    const { toggleCheckout, checkout } = this.state;
    
    res = this.billing.current.getBillingState();
    const { provinceData, stateData } = res; //get code
    var builder = localStorage.getItem("_cart");
    var dataCart = JSON.parse(builder);
 
    if (!res.name || !res.address || !res.phone) {
      return toast.error("Hãy nhập đầy đủ thông tin");
    }
    if(res.phone && res.phone.length !== 10 ) {
      return toast.error("Số điện thoại phải dài 10 chữ số");
    }
    const user = await callApi(`users/${res.phone}/getByPhone`, 'GET', null)
    if(user.status === 200) {
      const dataUser = user.data;
      if(dataUser.name !== res.name || dataUser.email !== res.email || dataUser.address !== res.address){
        MySwal.fire({
          title: "Thay đổi thông tin?",
          text: "Thông tin cá nhân của bạn đã bị thay đổi, bạn có muốn thay đổi không?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "có, Cập nhật ngay!",
          cancelButtonText: "Không",
        }).then(async (result) => {
          if(result.value) {
            this.props.update_me({
              ...dataUser,
              name: res.name,
              email: res.email,
              address: res.address
            })
          }
        });
      }
    }

    let addressProvince;
    if (res.provinces && res.provinces.length) {
      res.provinces.map((item) => {
        if (item.code === provinceData) {
          addressProvince = item.name;
          return addressProvince;
        }
        return { message: "error" };
      }); //output name province
    }

    let addressState;
    if (res.states && res.states.length) {
      res.states.map((item) => {
        if (item.code === stateData) {
          addressState = item.name;
          return addressState;
        }
        return { message: "error" };
      }); //output name state
    }
   
    let amount = 0;
    let dataItems = [];
    dataCart.forEach((item) => {
      dataItems.push({
        sku: item.id,
        name: item.nameProduct,
        description: item.description,
        quantity: item.quantity,
        price: item.price,
        currency: "VND",
      });
    });
    if (dataCart.length > 0) {
      amount = dataCart.reduce((sum, item) => {
        return (sum += item.quantity * item.price);
      }, 0);
    } //output total Amount
    this.setState({
      toggleCheckout: !toggleCheckout,
      checkout: !checkout
    });
  };

  changeToggle(result) {
    if (result === true) {
      this.setState({
        checkout: true,
        result: true,
      });
    }
    //CLEAR CART AFTER CHECKOUT
    this.props.reset_cart();
  }

  render() {
    const {
      redirectTo,
      toggleCheckout,
      shippingAddress,
      checkout,
      result,
    } = this.state;
    if (redirectTo) {
      return <Redirect to="/after-checkout"></Redirect>;
    }

    return (
      <div className="checkout-area">
        <div className="container">
          <div
            className="row"
            style={{ textAlign: "center", marginTop: -25, paddingBottom: 10 }}
          >
            <div className="processOrder">
              <div className="container processOrder-body">
                <ul className="progressbar">
                  <li className="active">Đăng nhập</li>
                  {shippingAddress ? (
                    <li className="active">Địa chỉ</li>
                  ) : (
                    <li>Địa chỉ</li>
                  )}
                  {checkout ? (
                    <li className="active">Kiểm đơn</li>
                  ) : (
                    <li>Kiểm đơn</li>
                  )}
                  {result ? (
                    <li className="active">Kết quả</li>
                  ) : (
                    <li>Kết quả</li>
                  )}
                </ul>
              </div>
            </div>
          </div>
          {result ? (
            <div className="row">
              <div className="col-lg-12">
                <div className="error-wrapper text-center ptb-50 pt-xs-20">
                  <div className="text-notice">
                    <img
                      src="https://i.ibb.co/pvDhxPj/checked-ok-yes-icon-1320196391133448530.png"
                      alt="checked"
                      height="70px"
                    />
                    <h1 >Cảm ơn.</h1>
                  </div>
                  <div className="text-notice">
                    <h1>Đơn hàng của bạn đã được đặt thành công.</h1>
                  </div>
                  <div>
                    <p>
                      <i>
                        Chi tiết đơn hàng của bạn sẽ được gửi qua email của bạn.
                        Hãy kiểm tra email để kiểm tra trạng thái đơn hàng.
                      </i>
                    </p>
                  </div>
                  <div className="container-buyMore"><Link to="/products" className="buyMore">Thoát hoặc Đặt hàng tiếp</Link></div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              {toggleCheckout ? (
                <YourOrder
                  changeToggle={(result) => this.changeToggle(result)}
                  order={resultOrder}
                  submitOrder={() => this.submitOrder()}
                ></YourOrder>
              ) : (
                <BillDetail ref={this.billing}></BillDetail>
              )}
              <div className="col-12" style={{ textAlign: "center" }}>
                {!toggleCheckout ? (
                  <button
                    onClick={() => this.toggleCheckout()}
                    className="btn btn-primary"
                    style={{ marginTop: -25, marginBottom: 10, backgroundColor: "#ee4d2d", border: "none" }}
                  >
                    Tiếp theo
                  </button>
                ) : null}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cartStore: state.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reset_cart: () => {
      dispatch(actClearRequest());
    },
    update_me: (data) => {
      dispatch(actUpdateMeRequset(data))
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CheckOut);

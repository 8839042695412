import React from 'react';
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ReceiptIcon from '@material-ui/icons/Receipt';
import MoreIcon from '@material-ui/icons/MoreVert';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {startLoading, doneLoading} from '../../utils/loading'

const useStyles = makeStyles((theme) => ({
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('600')]: {
      display: 'none',
    },
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

export default function PrimarySearchAppBar(props) {
  const {user, logout} = props;
  const classes = useStyles();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const loadingPage = () => {
    handleMobileMenuClose();
    startLoading();
    doneLoading();
  }

  const handleLogout = (logout) => {
    handleMobileMenuClose();
    return logout();
  }

  const mobileMenuId = 'primary-search-account-menu-mobile';
  
  const MenuViewMore = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <Link style={{margin:'0'}} onClick={()=>loadingPage()} to="/login" className="fix-link-color language-selector-wrapper">
        <MenuItem className={classes.menuItem}>
            <IconButton aria-label="show 4 new mails" color="inherit">
              <AccountCircle />
            </IconButton>
            <p style={{marginBottom:'0'}}>Đăng nhập</p>
        </MenuItem>
      </Link>
      <Link style={{margin:'0'}} onClick={()=>loadingPage()} to="/contact" className="fix-link-color language-selector-wrapper">
        <MenuItem className={classes.menuItem}>
          <IconButton aria-label="show 4 new mails" color="inherit">
              <ReceiptIcon />
          </IconButton>
          <p style={{marginBottom:'0'}}>Nhận báo giá</p>
        </MenuItem>
      </Link>
    </Menu>
  )

  const MenuLogined = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <Link style={{margin:'0'}} onClick={()=>loadingPage()} to="/contact" className="fix-link-color language-selector-wrapper">
        <MenuItem className={classes.menuItem}>
          <IconButton aria-label="show 4 new mails" color="inherit">
              <ReceiptIcon />
          </IconButton>
          <p style={{marginBottom:'0'}}>Nhận báo giá</p>
        </MenuItem>
      </Link>
      <Link style={{margin:'0'}} onClick={()=>handleLogout(logout)} to="/login" className="fix-link-color language-selector-wrapper">
        <MenuItem className={classes.menuItem}>
          <IconButton aria-label="show 11 new notifications" color="inherit">
            <ExitToAppIcon />
          </IconButton>
          <p style={{marginBottom:'0'}}>Đăng xuất</p>
        </MenuItem>
      </Link>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <div className={classes.sectionMobile}>
        <IconButton
          aria-label="show more"
          aria-controls={mobileMenuId}
          aria-haspopup="true"
          onClick={handleMobileMenuOpen}
          color="inherit"
        >
          <MoreIcon style={{fontSize:"30px"}}/>
        </IconButton>
      </div>
      {
        user 
        ? MenuLogined
        : MenuViewMore
      }
    </div>
  );
}


import React, { Component } from 'react'
import ProductOtherItems from './ProductOtherItems';
import ProductMobileItem from '../common/ProductItemMobile/ProductItemMobile';
import { connect } from 'react-redux'
import Slider from "react-slick";
import './style.css'

class ProductOther extends Component {
  render() {
    const { products } = this.props;
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 7,
      slidesToScroll: 5,
      // autoplay: true,
      // autoplaySpeed: 5000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 4,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 760,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        }
      ]
    };

    return (
      <section className="product-area li-laptop-product product-Orther">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="li-section-title">
                <h2>
                  <span>Sản phẩm cùng danh mục</span>
                </h2>
              </div>
              <div className="slider-product-orther desktop">
                <Slider {...settings}>
                  {products && products.map((product, index) => {
                    return (
                      <div key={index}>
                        <ProductOtherItems product={product}> </ProductOtherItems>
                      </div>
                    )
                  })}
                </Slider>
              </div>
              <div className="slider-product-orther-mobile mobile-block">
                <Slider {...settings}>
                  {products && products.map((product, index) => {
                    return (
                      <ProductMobileItem
                        product={product} 
                        category = {{
                          'id': product.categoryId_categories && product.categoryId_categories.length > 0 ? product.categoryId_categories[0].id : '',
                          'nameCategory': product.categoryId_categories && product.categoryId_categories.length > 0 ? product.categoryId_categories[0].nameCategory : ''
                        }}
                      /> 
                    )
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.productsOther
  }
}

export default connect(mapStateToProps, null)(ProductOther)

import React, { Component } from 'react'
// import BeautyStars from 'beauty-stars';
import { actGetProductOfCategoryRequest, actFetchProductsOfProducerRequest, actFetchProductsPriceRequest, actFetchProductsOfRatingPointRequest } from '../../redux/actions/products';
import { connect } from 'react-redux'
import { formatNumber } from '../../config/TYPE'
import './style.css'
let id;
class LeftOptions extends Component {

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleProductsProducer = (id) => {
    this.props.fetch_products_producer(id).then(res => {
      this.props.totalProducts(res.total);
    })
  }

  handleProductsPrice = (price) => {
    this.props.handleFilterPrice(price)
  }

  handleProductsPoint = (point) => {
    id = this.props.id;
    this.props.fetch_products_point_rating(id, point).then(res => {
      this.props.totalProducts(res.total);
    })
  }

  handleProductsProducerAll = () => {
    this.props.handleFilterAll();
    id = this.props.id;
    this.props.fetch_products(id).then(res => {
      this.props.totalProducts(res.total);
    })

  }

  render() {
    
    return (
      <div className="col-lg-3 order-2 order-lg-1 desktop">
        <div className="sidebar-categores-box">
          <div className="sidebar-title">
            <h2 className="fix-producers">Bộ lọc</h2>
          </div>
          <div className="filter-product">
            <button onClick={() => this.handleProductsProducerAll()} className="btn-clear-all mb-sm-30 mb-xs-30">Tất cả</button>
            {this.props.sort !== '-createAt' && 
              <button className="btn-clear-all mb-sm-30 mb-xs-30">
                {
                  this.props.sort === 'nameProduct' && 'Tên (A-Z)' 
                }
                {
                  this.props.sort === '-nameProduct' && 'Tên (Z-A)' 
                }
                {
                  this.props.sort === 'price' && 'Giá (Thấp - Cao)' 
                }
                {
                  this.props.sort === '-price' && 'Giá (Cao - Thấp)' 
                }
              </button>
            }
            {this.props.price && 
              <button className="btn-clear-all mb-sm-30 mb-xs-30">
                {
                  this.props.price.from 
                  ?  `${this.props.price.from.toString().slice(0, this.props.price.from.toString().length - 3)}k - ${this.props.price.to.toString().slice(0, this.props.price.to.toString().length - 3)}k`
                  : `Lớn hơn ${this.props.price.to.toString().slice(0, this.props.price.to.toString().length - 3)}k`
                }
              </button>
            }
          </div>
          <div className="filter-sub-area pt-sm-10 pt-xs-10">
            <h5 className="fix-producers filter-sub-titel">Giá</h5>
            <div className="size-checkbox">
              <form action="/">
                <ul className="ListPrice">
                  <li><p  onClick={(price) => this.handleProductsPrice({from:10000, to:50000})}><b>Từ</b> {formatNumber.format((10000))}  - {formatNumber.format((50000))}</p></li>
                  <li><p  onClick={(price) => this.handleProductsPrice({from:50000, to:200000})}><b>Từ</b> {formatNumber.format((50000))}  - {formatNumber.format((200000))}</p></li>
                  <li><p  onClick={(price) => this.handleProductsPrice(({from:200000, to:1000000}))}><b>Từ</b> {formatNumber.format((200000))}  - 1 Triệu</p></li>
                  <li><p  onClick={(price) => this.handleProductsPrice({to:1000000})}><b>Lớn hơn</b> 1 Triệu</p></li>
                </ul>
              </form>
            </div>
          </div>
          {/* filter-sub-area end */}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.products,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetch_products: (id) => {
      return dispatch(actGetProductOfCategoryRequest(id));
    },
    fetch_products_producer: (id) => {
      return dispatch(actFetchProductsOfProducerRequest(id));
    },
    fetch_products_price: (price, id) => {
      return dispatch(actFetchProductsPriceRequest(price, id));
    },
    fetch_products_point_rating: (categoryId, point) => {
      return dispatch(actFetchProductsOfRatingPointRequest(categoryId, point));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftOptions)


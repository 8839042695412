import React,{useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {config} from 'config';
import 'react-toastify/dist/ReactToastify.css';
import ProductModalMobile from '../ProductModelMobile/ProductModelMobile';
import NoticeDiscount from '../NoticeDiscount/NoticeDiscount';

import './ProductItemFlex.css';

function C_productItemMobile(props) {
    const {product, category} = props;
    const [openMobile, setOpenMobile] = useState(false);
    const handleOpen = () => {
        setOpenMobile(true);
    };
    
    const handleClose = () => {
        setOpenMobile(false)
    };

    return (
        <div className="ProductItemFlex PIM-background">
            <Link 
                to={`/products/${product.id}`}
                className="product-flex-img"
            >
                <img 
                    src={`${config.api.url_BE}/${product.image}`} 
                    alt={product.nameProduct}
                />
            </Link>
            <div className="CPM-body text-truncate">
                    <Link 
                        to={`/products/${product.id}`} 
                        className = "Product-name-mobile text-truncate"
                    >
                        {product.nameProduct}
                    </Link>
                    <Link 
                        to={`/categories/${category ? category.id : ''}`}  
                        className = "name-category text-truncate"
                    >
                        {
                            category && category.nameCategory 
                            ? category.nameCategory 
                            : null
                        }
                    </Link>
                {
                    product.discountPrice
                    ? (
                        <div className="CPIM-PriceContainer">
                            <span className="CPIM-linethrought text-truncate">{product.price.toLocaleString('vi-VN', {style: 'currency', currency: 'VND'})}</span>
                            <span className="CPIM-RPrice text-truncate">{product.discountPrice.toLocaleString('vi-VN', {style: 'currency', currency: 'VND'})}</span>
                        </div>
                    )
                    : <div className="CPIM-Realprice">
                        <span className="text-truncate">{product.price.toLocaleString('vi-VN', {style: 'currency', currency: 'VND'})}</span>
                    </div>
                }
                <div className="CPM-btn_buy" onClick={handleOpen}>Đặt hàng</div>
            </div>
            {
                product.discountPrice 
                ? <NoticeDiscount
                    style = {{top:"0", left:"0"}}
                    discount={(100 - 100*parseInt(product.discountPrice)/parseInt(product.price)).toFixed(0)}
                />
                : null
            }
            <ProductModalMobile
                product={product}
                open = {openMobile}
                handleClose = {handleClose}
            />
        </div>
    )
}

C_productItemMobile.propTypes = {
    product: PropTypes.object.isRequired
}

export default C_productItemMobile;


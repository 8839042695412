import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import { connect } from 'react-redux'
import { formatNumber } from '../../config/TYPE'
import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

class SumTotal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectYourOrder: false
    }
  }

  checkAuthenticate = (sumPrice) => {
    const { sumTotal, minPrice } = this.props;
    console.log(minPrice, sumPrice)
    if (!sumTotal.length) {
      return toast.error('Chọn sản phẩm trước khi đặt hàng');
    }
    if(sumPrice < minPrice ) {
      return toast.error(`Đơn hàng phải lớn hơn ${formatNumber.format(parseInt(minPrice))}`);
    }
    this.setState({
      redirectYourOrder: true
    })
  }

  render() {
    const { redirectYourOrder } = this.state;
    const { sumTotal } = this.props;
    let sumPrice = 0;
    let sumDiscount = 0;
  
    if (sumTotal.length > 0) {
      sumPrice = sumTotal.reduce((sum, item) => {
        return sum += item.quantity * item.price
      }, 0)

      sumDiscount = sumTotal.reduce((sum, item) => {
        if(item.discountPrice) {
          return sum += item.quantity * (item.price - item.discountPrice)
        }
        return sum
      }, 0)
    }
    if (redirectYourOrder) {
      return <Redirect to="/checkout"></Redirect>
    }
 

    return (
      <div>
        <div className="cart-page-total">
          <h2>Tổng đơn hàng</h2>
          <ul>
            <li>Tổng giá <span>{sumPrice ? formatNumber.format(sumPrice) : 0}</span></li>
            <li>Khuyễn mãi <span>- {formatNumber.format(sumDiscount)}</span></li>
            <li style={{ color: '#ee4d2d' }}>Tổng <span>{formatNumber.format(sumPrice - sumDiscount)}</span></li>
          </ul>
          <button onClick={() => this.checkAuthenticate(sumPrice)} className="fix-text-checkout">Đặt hàng</button>
          <div className="container-buyMore"><Link to="/" className="buyMore">Đặt thêm</Link></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    sumTotal: state.cart,
    user: state.auth
  }
}
export default connect(mapStateToProps, null)(SumTotal)

import React, { Component } from 'react'
import LinkHere from '../components/LinkHere/LinkHere'
import ProductSearch from '../components/ProductAll/ProductSearch'

export default class ProductSearchPage extends Component {
  render() {
    const {keyWord} = this.props.match.match.params;

    return (
      <div>
        <LinkHere title={`Tìm kiếm / ${keyWord}`}></LinkHere>
        <ProductSearch keyWord={keyWord}></ProductSearch>
      </div>
    )
  }
}


import React, {useState, useEffect} from 'react';
import parse  from 'html-react-parser';
import { useDispatch } from 'react-redux';
import { actShowLoading, actHiddenLoading } from 'redux/actions/loading';
import callApi from 'utils/apiCaller';
import './PostDetail.css';
import {config} from 'config';
import * as ClassicEditor from '@ckeditor/ckeditor5-editor-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';

const Postdetail = (props) => {
    const {id} = props;
    const dispatch = useDispatch();
    const [product, setProduct] = useState();

    useEffect( async () => {
        dispatch(actShowLoading());
        const res = await callApi(`products/${id}`, "GET", null, null);
        if (res && res.status === 200) {
            setProduct(res.data);
            console.log('pro', res.data);
        }
        setTimeout(function(){ dispatch(actHiddenLoading()) }, 200);
    }, [id]);

    return (
        <div className="container">
            {product && <div className="PostDetail">
                <div className="post-img"><img src={`${config.api.url_BE}/${product.image}`} alt={product.nameProduct}></img></div>
                <div className="post-short-description">{parse(product.description || '')}</div>
                <div className="li-product-tab product-longdesc-title">
                    <ul className="nav li-product-menu">
                    <li>
                        <a className="active" data-toggle="tab" href="#description">
                        <span>Mô tả</span>
                        </a>
                    </li>
                    </ul>
                </div>
                <div className="post-long-description" style={{pointerEvents: 'none'}}>
                    <CKEditor
                        editor={ ClassicEditor  }
                        data={product.longDescription}
                        config = { {
                            isReadOnly: true
                        }}
                    />
                </div>
            </div>}
        </div>
    );
}

export default Postdetail;
